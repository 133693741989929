import classNames from 'classnames';

import Field from "matrix-react-sdk/lib/components/views/elements/Field";

import sdk from "matrix-react-sdk/lib/index";
import { _t, _td } from 'matrix-react-sdk/lib/languageHandler';
import ProfileSettings from "matrix-react-sdk/lib/components/views/settings/ProfileSettings";

export default class VectorProfileSettings extends ProfileSettings {
    static replaces = "ProfileSettings";

    render () {
        const AccessibleButton = sdk.getComponent('views.elements.AccessibleButton');

        let showOverlayAnyways = true;
        let avatarElement = <div className="mx_ProfileSettings_avatarPlaceholder" />;
        
        if (this.state.avatarUrl) {
            showOverlayAnyways = false;
            avatarElement = <img src={this.state.avatarUrl}
                alt={_t("Profile picture")} />;
        }

        const avatarOverlayClasses = classNames({
            "mx_ProfileSettings_avatarOverlay": true,
            "mx_ProfileSettings_avatarOverlay_show": showOverlayAnyways,
        });

        const avatarHoverElement = (
            <div className={avatarOverlayClasses} onClick={this._uploadAvatar}>
                <span className="mx_ProfileSettings_avatarOverlayText">{_t("Upload profile picture")}</span>
                <div className="mx_ProfileSettings_avatarOverlayImgContainer">
                    <div className="mx_ProfileSettings_avatarOverlayImg" />
                </div>
            </div>
        );

        return (
            <form onSubmit={this._saveProfile} autoComplete="false" noValidate={true}>
                <input type="file" ref="avatarUpload" className="mx_ProfileSettings_avatarUpload"
                    onChange={this._onAvatarChanged} accept="image/*" />
                <div className="mx_ProfileSettings_profile">
                    <div className="mx_ProfileSettings_controls">
                        <p>
                            { this.state.userId }
                        </p>
                        <Field id="profileDisplayName" label={_t("Display Name")}
                            type="text" value={ this.state.displayName } autoComplete="off"
                            onChange={ this._onDisplayNameChanged } />
                    </div>
                    <div className="mx_ProfileSettings_avatar">
                        { avatarElement }
                        { avatarHoverElement }
                    </div>
                </div>
                <AccessibleButton onClick={this._saveProfile} kind="primary"
                    disabled={!this.state.enableProfileSave}>
                    {_t("Save")}
                </AccessibleButton>
            </form>
        );
    }
}