
import MatrixClientPeg from 'matrix-react-sdk/lib/MatrixClientPeg';
import sdk from "matrix-react-sdk/lib/index";
import { _t, _td } from 'matrix-react-sdk/lib/languageHandler';
import HelpUserSettingsTab from 'matrix-react-sdk/lib/components/views/settings/tabs/user/HelpUserSettingsTab';

import PlatformPeg from 'matrix-react-sdk/lib/PlatformPeg';

export default class VectorHelpUserSettingsTab extends HelpUserSettingsTab {
    static replaces = "HelpUserSettingsTab"
    
    render() {
        const AccessibleButton = sdk.getComponent('views.elements.AccessibleButton');

        const vectorVersion = this.state.vectorVersion
            ? this.state.vectorVersion
            : 'unknown';

        let olmVersion = MatrixClientPeg.get().olmVersion;
        olmVersion = olmVersion ? `${olmVersion[0]}.${olmVersion[1]}.${olmVersion[2]}` : '<not-enabled>';

        let updateButton = null;
        if (this.state.canUpdate) {
            const platform = PlatformPeg.get();
            updateButton = (
                <AccessibleButton onClick={platform.startUpdateCheck} kind='primary'>
                    {_t('Check for update')}
                </AccessibleButton>
            );
        }

        return (
            <div className="mx_SettingsTab mx_HelpUserSettingsTab">
                <div className="mx_SettingsTab_heading">{_t("Help & About")}</div>                
                <div className='mx_SettingsTab_section mx_HelpUserSettingsTab_versions'>
                    <span className='mx_SettingsTab_subheading'>{_t("Versions")}</span>
                    <div className='mx_SettingsTab_subsectionText'>
                        {_t("riot-web version:")} {vectorVersion}<br />
                        {_t("olm version:")} {olmVersion}<br />
                        {updateButton}
                    </div>
                </div>
                <div className='mx_SettingsTab_section mx_HelpUserSettingsTab_versions'>
                    <span className='mx_SettingsTab_subheading'>{_t("Advanced")}</span>
                    <div className='mx_SettingsTab_subsectionText'>
                        {_t("Homeserver is")} <code>{MatrixClientPeg.get().getHomeserverUrl()}</code><br />
                        {_t("Identity Server is")} <code>{MatrixClientPeg.get().getIdentityServerUrl()}</code><br />
                        {_t("Access Token:") + ' '}

                        <AccessibleButton element="span" onClick={this._showSpoiler}
                            data-spoiler={MatrixClientPeg.get().getAccessToken()}>
                            &lt;{_t("click to reveal")}&gt;
                        </AccessibleButton>

                        <div className='mx_HelpUserSettingsTab_debugButton'>
                            <AccessibleButton onClick={this._onClearCacheAndReload} kind='danger'>
                                {_t("Clear Cache and Reload")}
                            </AccessibleButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}