'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import SlateMessageComposer from 'matrix-react-sdk/lib/components/views/rooms/SlateMessageComposer';
import { _t, _td } from 'matrix-react-sdk/lib/languageHandler';
import CallHandler from 'matrix-react-sdk/lib/CallHandler';
import MatrixClientPeg from 'matrix-react-sdk/lib/MatrixClientPeg';
import sdk from 'matrix-react-sdk/lib/index';
import dis from 'matrix-react-sdk/lib/dispatcher';
// import RoomViewStore from 'matrix-react-sdk/lib/stores/RoomViewStore';
// import SettingsStore, { SettingLevel } from 'matrix-react-sdk/lib/settings/SettingsStore';
import VectorStickerpicker from "./VectorStickerpicker";
import ContentMessages from "matrix-react-sdk/lib/ContentMessages";
import classNames from 'classnames';

import E2EIcon from 'matrix-react-sdk/lib/components/views/rooms/E2EIcon';

function ComposerAvatar(props) {
    const MemberStatusMessageAvatar = sdk.getComponent('avatars.MemberStatusMessageAvatar');
    return <div className="mx_MessageComposer_avatar">
        <MemberStatusMessageAvatar member={props.me} width={24} height={24} />
    </div>;
}

ComposerAvatar.propTypes = {
    me: PropTypes.object.isRequired,
}

function CallButton(props) {
    const AccessibleButton = sdk.getComponent('elements.AccessibleButton');
    const onVoiceCallClick = (ev) => {
        dis.dispatch({
            action: 'place_call',
            type: "voice",
            room_id: props.roomId,
        });
    };

    return <AccessibleButton className="mx_MessageComposer_button mx_MessageComposer_voicecall"
        onClick={onVoiceCallClick}
        title={_t('Voice call')}
    />
}

CallButton.propTypes = {
    roomId: PropTypes.string.isRequired
}

function VideoCallButton(props) {
    const AccessibleButton = sdk.getComponent('elements.AccessibleButton');
    const onCallClick = (ev) => {
        dis.dispatch({
            action: 'place_call',
            type: ev.shiftKey ? "screensharing" : "video",
            room_id: props.roomId,
        });
    };

    return <AccessibleButton className="mx_MessageComposer_button mx_MessageComposer_videocall"
        onClick={onCallClick}
        title={_t('Video call')}
    />;
}

VideoCallButton.propTypes = {
    roomId: PropTypes.string.isRequired,
};

function HangupButton(props) {
    const AccessibleButton = sdk.getComponent('elements.AccessibleButton');
    const onHangupClick = () => {
        const call = CallHandler.getCallForRoom(props.roomId);
        if (!call) {
            return;
        }
        dis.dispatch({
            action: 'hangup',
            // hangup the call for this room, which may not be the room in props
            // (e.g. conferences which will hangup the 1:1 room instead)
            room_id: call.roomId,
        });
    };
    return <AccessibleButton className="mx_MessageComposer_button mx_MessageComposer_hangup"
        onClick={onHangupClick}
        title={_t('Hangup')}
    />;
}

HangupButton.propTypes = {
    roomId: PropTypes.string.isRequired,
}

function FormattingButton(props) {
    const AccessibleButton = sdk.getComponent('elements.AccessibleButton');
    return <AccessibleButton
        element="img"
        className="mx_MessageComposer_formatting"
        alt={_t("Show Text Formatting Toolbar")}
        title={_t("Show Text Formatting Toolbar")}
        src={require("matrix-react-sdk/res/img/button-text-formatting.svg")}
        style={{ visibility: props.showFormatting ? 'hidden' : 'visible' }}
        onClick={props.onClickHandler}
    />;
}

FormattingButton.propTypes = {
    showFormatting: PropTypes.bool.isRequired,
    onClickHandler: PropTypes.func.isRequired,
}

class UploadButton extends React.Component {
    static propTypes = {
        roomId: PropTypes.string.isRequired,
    }
    constructor(props, context) {
        super(props, context);
        this.onUploadClick = this.onUploadClick.bind(this);
        this.onUploadFileInputChange = this.onUploadFileInputChange.bind(this);
    }

    onUploadClick(ev) {
        if (MatrixClientPeg.get().isGuest()) {
            dis.dispatch({ action: 'require_registration' });
            return;
        }
        this.refs.uploadInput.click();
    }

    onUploadFileInputChange(ev) {
        if (ev.target.files.length === 0) return;

        // take a copy so we can safely reset the value of the form control
        // (Note it is a FileList: we can't use slice or sesnible iteration).
        const tfiles = [];
        for (let i = 0; i < ev.target.files.length; ++i) {
            tfiles.push(ev.target.files[i]);
        }

        ContentMessages.sharedInstance().sendContentListToRoom(
            tfiles, this.props.roomId, MatrixClientPeg.get(),
        );

        // This is the onChange handler for a file form control, but we're
        // not keeping any state, so reset the value of the form control
        // to empty.
        // NB. we need to set 'value': the 'files' property is immutable.
        ev.target.value = '';
    }

    render() {
        const uploadInputStyle = { display: 'none' };
        const AccessibleButton = sdk.getComponent('elements.AccessibleButton');
        return (
            <AccessibleButton className="mx_MessageComposer_button mx_MessageComposer_upload"
                onClick={this.onUploadClick}
                title={_t('Upload file')}
            >
                <input ref="uploadInput" type="file"
                    style={uploadInputStyle}
                    multiple
                    onChange={this.onUploadFileInputChange}
                />
            </AccessibleButton>
        );
    }
}

export default class VectorSlateMessageComposer extends SlateMessageComposer {
    static replaces = "SlateMessageComposer"

    render() {
        const controls = [
            this.state.me ? <ComposerAvatar key="controls_avatar" me={this.state.me} /> : null,
            this.props.e2eStatus ? <E2EIcon key="e2eIcon" status={this.props.e2eStatus} className="mx_MessageComposer_e2eIcon" /> : null,
        ];

        if (!this.state.tombstone && this.state.canSendMessages) {
            // This also currently includes the call buttons. Really we should
            // check separately for whether we can call, but this is slightly
            // complex because of conference calls.

            const MessageComposerInput = sdk.getComponent("rooms.MessageComposerInput");
            const showFormattingButton = this.state.inputState.isRichTextEnabled;
            const callInProgress = this.props.callState && this.props.callState !== 'ended';

            controls.push(
                <MessageComposerInput
                    ref={(c) => this.messageComposerInput = c}
                    key="controls_input"
                    room={this.props.room}
                    placeholder={this.renderPlaceholderText()}
                    onInputStateChanged={this.onInputStateChanged}
                    permalinkCreator={this.props.permalinkCreator} />,
                showFormattingButton ? <FormattingButton key="controls_formatting"
                    showFormatting={this.state.showFormatting} onClickHandler={this.onToggleFormattingClicked} /> : null,
                // <VectorStickerpicker key='stickerpicker_controls_button' room={this.props.room} />,
                <UploadButton key="controls_upload" roomId={this.props.room.roomId} />,
                callInProgress ? <HangupButton key="controls_hangup" roomId={this.props.room.roomId} /> : null,
                callInProgress ? null : <CallButton key="controls_call" roomId={this.props.room.roomId} />,
                callInProgress ? null : <VideoCallButton key="controls_videocall" roomId={this.props.room.roomId} />,
            );
        } else if (this.state.tombstone) {
            const replacementRoomId = this.state.tombstone.getContent()['replacement_room'];

            const continuesLink = replacementRoomId ? (
                <a href={makeRoomPermalink(replacementRoomId)}
                    className="mx_MessageComposer_roomReplaced_link"
                    onClick={this._onTombstoneClick}
                >
                    {_t("The conversation continues here.")}
                </a>
            ) : '';

            controls.push(<div className="mx_MessageComposer_replaced_wrapper">
                <div className="mx_MessageComposer_replaced_valign">
                    <img className="mx_MessageComposer_roomReplaced_icon" src={require("matrix-react-sdk/res/img/room_replaced.svg")} />
                    <span className="mx_MessageComposer_roomReplaced_header">
                        {_t("This room has been replaced and is no longer active.")}
                    </span><br />
                    {continuesLink}
                </div>
            </div>);
        } else {
            controls.push(
                <div key="controls_error" className="mx_MessageComposer_noperm_error">
                    {_t('You do not have permission to post to this room')}
                </div>,
            );
        }

        const showFormatBar = this.state.showFormatting && this.state.inputState.isRichTextEnabled;

        const wrapperClasses = classNames({
            mx_MessageComposer_wrapper: true,
            mx_MessageComposer_hasE2EIcon: !!this.props.e2eStatus,
        });

        return (
            <div className="mx_MessageComposer">
                <div className={wrapperClasses}>
                    <div className="mx_MessageComposer_row">
                        {controls}
                    </div>
                </div>
                {showFormatBar ? this.renderFormatBar() : null}
            </div>
        );
    };
}