import { instanceForInstanceId } from 'matrix-react-sdk/lib/utils/DirectoryUtils';
import NetworkDropdown from "matrix-react-sdk/lib/components/views/directory/NetworkDropdown";

export default class VectorNetworkDropdown extends NetworkDropdown {
    static replaces = "NetworkDropdown";

    _makeMenuOption = (server, instance, includeAll, handleClicks) => {
        if (handleClicks === undefined) handleClicks = true;

        let icon;
        let name;
        let key;

        if ( !instance && includeAll ) {
            key = server;
            name = server;
        }
        else {
            return ;
        }

        const clickHandler = handleClicks ? this.onMenuOptionClick.bind(this, server, instance, includeAll) : null;

        return <div key={key} className="mx_NetworkDropdown_networkoption" onClick={clickHandler}>
            {icon}
            <span className="mx_NetworkDropdown_menu_network">{name}</span>
        </div>;
    }

    render() {
        let currentValue;

        let menu;
        if ( this.state.expanded ) {
            const menuOptions = this._getMenuOptions();
            menu = <div className="mx_NetworkDropdown_menu">
                {menuOptions}
            </div>;

            currentValue = <input type="text" className="mx_NetworkDropdown_networkoption"
                ref={ this.collectInputTextBox } onKeyUp={ this.onInputKeyUp }
                placeholder="tvg.mx"
            />;
        }
        else {
            const instance = instanceForInstanceId(this.props.protocols, this.state.selectedInstanceId);
            currentValue = this._makeMenuOption(
                this.state.selectedServer, instance, true, false,
            );
        }

        return (
            <div className="mx_NetworkDropdown" ref={ this.collectRoot }>
                <div className="mx_NetworkDropdown_input mx_no_textinput" onClick={ this.onInputClick }>
                    { currentValue }
                    <span className="mx_NetworkDropdown_arrow" />
                    { menu }
                </div>
            </div>
        );
    }
}