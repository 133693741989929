import React from 'react';
import PropTypes from 'prop-types';

import { _t, _td } from 'matrix-react-sdk/lib/languageHandler';
import MatrixClientPeg from 'matrix-react-sdk/lib/MatrixClientPeg';
import AccessibleButton from "matrix-react-sdk/lib/components/views/elements/AccessibleButton";
import Field from "matrix-react-sdk/lib/components/views/elements/Field";
import CountryDropdown from "matrix-react-sdk/lib/components/views/auth/CountryDropdown";
import sdk from 'matrix-react-sdk/lib/index';
import Modal from 'matrix-react-sdk/lib/Modal';

import PhoneNumbers from "matrix-react-sdk/lib/components/views/settings/account/PhoneNumbers";

export class ExistingPhoneNumber extends React.Component {
    static propTypes = {
        msisdn: PropTypes.object.isRequired,
        onRemoved: PropTypes.func.isRequired,
    };

    constructor() {
        super();

        this.state = {
            verifyRemove: false,
        };
    }

    _onRemove = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({ verifyRemove: true });
    };

    _onDontRemove = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({ verifyRemove: false });
    };

    _onActuallyRemove = (e) => {
        e.stopPropagation();
        e.preventDefault();

        MatrixClientPeg.get().deleteThreePid(this.props.msisdn.medium, this.props.msisdn.address).then(() => {
            return this.props.onRemoved(this.props.msisdn);
        }).catch((err) => {
            const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
            console.error("Unable to remove contact information: " + err)
            
            Modal.createTrackedDialog('Remove 3pid failed', '', ErrorDialog, {
                title: _t("Unable to remove contact information"),
                description: ((err && err.message) ? err.message : _t("Operation failed")),
            });
        });
    };

    render () {
        if (this.state.verifyRemove) {
            return (
                <div className="mx_ExistingPhoneNumber">
                    <span className="mx_ExistingPhoneNumber_promptText">
                        {_t("Remove %(phone)s?", { phone: this.props.msisdn.address })}
                    </span>
                    <AccessibleButton onClick={this._onActuallyRemove} kind="danger_sm"
                        className="mx_ExistingPhoneNumber_confirmBtn">
                        {_t("Remove")}
                    </AccessibleButton>
                    <AccessibleButton onClick={this._onDontRemove} kind="link_sm"
                        className="mx_ExistingPhoneNumber_confirmBtn">
                        {_t("Cancel")}
                    </AccessibleButton>
                </div>
            );
        }
        
        return (
            <div className="mx_ExistingPhoneNumber">
                <span className="mx_ExistingPhoneNumber_address">+{ this.props.msisdn.address }</span>
                <AccessibleButton onClick={this._onRemove} kind="danger_sm">
                    {_t("Remove")}
                </AccessibleButton>
            </div>
        );
    }
}

export default class VectorPhoneNumbers extends PhoneNumbers {
    static replaces = "PhoneNumbers";

    render() {
        const existingPhoneElements = this.props.msisdns.map((p) => {
            return <ExistingPhoneNumber msisdn={p} onRemoved={this._onRemoved} key={p.address} />;
        });

        let addVerifySection = (
            <AccessibleButton onClick={this._onAddClick} kind="primary">
                {_t("Add")}
            </AccessibleButton>
        );

        if ( this.state.verifying ) {
            const msisdn = this.state.verifyMsisdn;
            
            addVerifySection = (
                <div>
                    <div>
                        {_t("A text message has been sent to +%(msisdn)s. " +
                            "Please enter the verification code it contains.", { msisdn: msisdn })}
                        <br />
                        { this.state.verifyError }
                    </div>
                    <form onSubmit={ this._onContinueClick } autoComplete="false" noValidate={true}>
                        <Field id="mx_PhoneNumbers_newPhoneNumberCode"
                            type="text"
                            label={_t("Verification code")}
                            autoComplete="off"
                            disabled={ this.state.continueDisabled }
                            value={ this.state.newPhoneNumberCode }
                            onChange={ this._onChangeNewPhoneNumberCode }
                        />
                        <AccessibleButton onClick={ this._onContinueClick } kind="primary"
                            disabled={ this.state.continueDisabled }>
                            {_t("Continue")}
                        </AccessibleButton>
                    </form>
                </div>
            );
        }

        const phoneCountry = <CountryDropdown onOptionChange={ this._onCountryChanged }
            className="mx_PhoneNumbers_country"
            value={ this.state.phoneCountry }
            disabled={ this.state.verifying }
            isSmall={ true }
            showPrefix={ true }
        />;

        return (
            <div className="mx_PhoneNumbers">
                { existingPhoneElements }
                <form onSubmit={ this._onAddClick } autoComplete="false"
                    noValidate={true} className="mx_PhoneNumbers_new">
                    <div className="mx_PhoneNumbers_input">
                        <Field id="mx_PhoneNumbers_newPhoneNumber"
                            type="text"
                            label={ _t("Phone Number") }
                            autoComplete="off"
                            disabled={ this.state.verifying }
                            prefix={ phoneCountry }
                            value={ this.state.newPhoneNumber }
                            onChange={ this._onChangeNewPhoneNumber }
                        />
                    </div>
                </form>
                { addVerifySection }
            </div>
        );
    }
}