import React from 'react';

import sdk from "matrix-react-sdk/lib/index";
import { _t, _td } from 'matrix-react-sdk/lib/languageHandler';
import AccessibleButton from 'matrix-react-sdk/lib/components/views/elements/AccessibleButton';
import GroupHeaderButtons from 'matrix-react-sdk/lib/components/views/right_panel/GroupHeaderButtons';
import MainSplit from 'matrix-react-sdk/lib/components/structures/MainSplit';
import RightPanel from 'matrix-react-sdk/lib/components/structures/RightPanel';
import classnames from 'classnames';

import GroupView from "matrix-react-sdk/lib/components/structures/GroupView";

export default class VectorGroupView extends GroupView {
    static replaces = "GroupView";

    render () {
        const GroupAvatar = sdk.getComponent("avatars.GroupAvatar");
        const Spinner = sdk.getComponent("elements.Spinner");
        const GeminiScrollbarWrapper = sdk.getComponent("elements.GeminiScrollbarWrapper");

        if ( this.state.summaryLoading && this.state.error == null || this.state.saving ) {
            return <Spinner />;
        }
        else if (this.state.summary && !this.state.error) {
            const summary = this.state.summary;

            let avatarNode;
            let nameNode;
            let shortDescNode;
            const rightButtons = [];

            if (this.state.editing && this.state.isUserPrivileged) {
                let avatarImage;
                if (this.state.uploadingAvatar) {
                    avatarImage = <Spinner />;
                } else {
                    const GroupAvatar = sdk.getComponent('avatars.GroupAvatar');
                    avatarImage = <GroupAvatar groupId={this.props.groupId}
                        groupName={this.state.profileForm.name}
                        groupAvatarUrl={this.state.profileForm.avatar_url}
                        width={28} height={28} resizeMethod='crop'
                    />;
                }

                avatarNode = (
                    <div className="mx_GroupView_avatarPicker">
                        <label htmlFor="avatarInput" className="mx_GroupView_avatarPicker_label">
                            {avatarImage}
                        </label>
                        <div className="mx_GroupView_avatarPicker_edit">
                            <label htmlFor="avatarInput" className="mx_GroupView_avatarPicker_label">
                                <img src={require("matrix-react-sdk/res/img/camera.svg")}
                                    alt={_t("Upload avatar")} title={_t("Upload avatar")}
                                    width="17" height="15" />
                            </label>
                            <input id="avatarInput" className="mx_GroupView_uploadInput" type="file" onChange={this._onAvatarSelected} />
                        </div>
                    </div>
                );

                const EditableText = sdk.getComponent("elements.EditableText");

                nameNode = <EditableText ref="nameEditor"
                    className="mx_GroupView_editable"
                    placeholderClassName="mx_GroupView_placeholder"
                    placeholder={ _t('Community Name') }
                    blurToCancel={false}
                    initialValue={ this.state.profileForm.name }
                    onValueChanged={ this._onNameChange }
                    tabIndex="1"
                    dir="auto" />;

                shortDescNode = <EditableText ref="descriptionEditor"
                    className="mx_GroupView_editable"
                    placeholderClassName="mx_GroupView_placeholder"
                    placeholder={ _t("Description") }
                    blurToCancel={false}
                    initialValue={ this.state.profileForm.short_description }
                    onValueChanged={ this._onShortDescChange }
                    tabIndex="2"
                    dir="auto" />;
            }
            else {
                const onGroupHeaderItemClick = this.state.isUserMember ? this._onEditClick : null;
                const groupAvatarUrl = summary.profile ? summary.profile.avatar_url : null;
                const groupName = summary.profile ? summary.profile.name : null;

                avatarNode = <GroupAvatar
                    groupId={this.props.groupId}
                    groupAvatarUrl={groupAvatarUrl}
                    groupName={groupName}
                    onClick={onGroupHeaderItemClick}
                    width={28} height={28}
                />;

                if (summary.profile && summary.profile.name) {
                    nameNode = <div onClick={onGroupHeaderItemClick}>
                        <span>{summary.profile.name}</span>
                        <span className="mx_GroupView_header_groupid">
                            ({this.props.groupId})
                        </span>
                    </div>;
                } 
                else {
                    nameNode = <span onClick={onGroupHeaderItemClick}>{this.props.groupId}</span>;
                }

                if (summary.profile && summary.profile.short_description) {
                    shortDescNode = <span onClick={onGroupHeaderItemClick}>{summary.profile.short_description}</span>;
                }
            }

            if (this.state.editing) {
                rightButtons.push(
                    <AccessibleButton className="mx_GroupView_textButton mx_RoomHeader_textButton"
                        key="_saveButton"
                        onClick={this._onSaveClick}
                    >
                        {_t('Save')}
                    </AccessibleButton>,
                );

                rightButtons.push(
                    <AccessibleButton className="mx_RoomHeader_cancelButton"
                        key="_cancelButton"
                        onClick={this._onCancelClick}
                    >
                        <img src={require("matrix-react-sdk/res/img/cancel.svg")} className="mx_filterFlipColor"
                            width="18" height="18" alt={_t("Cancel")} />
                    </AccessibleButton>,
                );
            }
            else {
                if (summary.user && summary.user.membership === 'join') {
                    rightButtons.push(
                        <AccessibleButton className="mx_GroupHeader_button mx_GroupHeader_editButton"
                            key="_editButton"
                            onClick={this._onEditClick}
                            title={_t("Community Settings")}
                        >
                        </AccessibleButton>,
                    );
                }
                
                // rightButtons.push(
                //     <AccessibleButton className="mx_GroupHeader_button mx_GroupHeader_shareButton"
                //         key="_shareButton"
                //         onClick={this._onShareClick}
                //         title={_t('Share Community')}
                //     >
                //     </AccessibleButton>,
                // );
            }

            const rightPanel = !this.props.collapsedRhs ? <RightPanel groupId={this.props.groupId} /> : undefined;

            const headerClasses = {
                "mx_GroupView_header": true,
                "light-panel": true,
                "mx_GroupView_header_view": !this.state.editing,
                "mx_GroupView_header_isUserMember": this.state.isUserMember,
            };

            return (
                <main className="mx_GroupView">
                    <div className={classnames(headerClasses)}>
                        <div className="mx_GroupView_header_leftCol">
                            <div className="mx_GroupView_header_avatar">
                                {avatarNode}
                            </div>
                            <div className="mx_GroupView_header_info">
                                <div className="mx_GroupView_header_name">
                                    {nameNode}
                                </div>
                                <div className="mx_GroupView_header_shortDesc">
                                    {shortDescNode}
                                </div>
                            </div>
                        </div>
                        <div className="mx_GroupView_header_rightCol">
                            {rightButtons}
                        </div>
                        <GroupHeaderButtons collapsedRhs={ this.props.collapsedRhs } />
                    </div>
                    <MainSplit collapsedRhs={this.props.collapsedRhs} panel={rightPanel}>
                        <GeminiScrollbarWrapper className="mx_GroupView_body">
                            {this._getMembershipSection()}
                            {this._getGroupSection()}
                        </GeminiScrollbarWrapper>
                    </MainSplit>
                </main>
            );
        }
        else if (this.state.error) {
            if (this.state.error.httpStatus === 404) {
                return (
                    <div className="mx_GroupView_error">
                        {_t('Community %(groupId)s not found', { groupId: this.props.groupId })}
                    </div>
                );
            } else {
                let extraText;
                if (this.state.error.errcode === 'M_UNRECOGNIZED') {
                    extraText = <div>{_t('This homeserver does not support communities')}</div>;
                }
                return (
                    <div className="mx_GroupView_error">
                        {_t('Failed to load %(groupId)s', { groupId: this.props.groupId })}
                        {extraText}
                    </div>
                );
            }
        }
        else {
            console.error("Invalid state for GroupView");
            return <div />;
        }
    }
}