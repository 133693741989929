import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { KeyCode, isOnlyCtrlOrCmdKeyEvent } from 'matrix-react-sdk/lib/Keyboard';
import PageTypes from 'matrix-react-sdk/lib/PageTypes';
import sdk from 'matrix-react-sdk/lib/index';
import dis from 'matrix-react-sdk/lib/dispatcher';

import TagOrderActions from 'matrix-react-sdk/lib/actions/TagOrderActions';
import RoomListActions from 'matrix-react-sdk/lib/actions/RoomListActions';
import ResizeHandle from 'matrix-react-sdk/lib/components/views/elements/ResizeHandle';
import LoggedInView from 'matrix-react-sdk/lib/components/structures/LoggedInView';

function canElementReceiveInput(el) {
    return el.tagName === "INPUT" ||
        el.tagName === "TEXTAREA" ||
        el.tagName === "SELECT" ||
        !!el.getAttribute("contenteditable");
}

export default class VectorLoggedInView extends LoggedInView {
    static replaces = 'LoggedInView';   
    
    constructor(props) {
        super(props);

        this.onPaste = this.onPaste.bind(this);
        this.onReactKeyDown = this.onReactKeyDown.bind(this);
        this.onNativeKeyDown = this.onNativeKeyDown.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onScrollKeyPressed = this.onScrollKeyPressed.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onRoomTileEndDrag = this.onRoomTileEndDrag.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.setResizeContainerRef = this.setResizeContainerRef.bind(this);
    }

    onPaste(ev) {
        let canReceiveInput = false;
        let element = ev.target;
        
        while (!canReceiveInput && element) {
            canReceiveInput = canElementReceiveInput(element);
            element = element.parentElement;
        }
        
        if (!canReceiveInput) {
            dis.dispatch({ action: 'focus_composer' }, true);
        }
    }

    onReactKeyDown(ev) {
        this.onKeyDown(ev);
    }

    onNativeKeyDown(ev) {
        if (ev.target === document.body) {
            this.onKeyDown(ev);
        }
    }

    onKeyDown(ev) {
        let handled = false;
        const ctrlCmdOnly = isOnlyCtrlOrCmdKeyEvent(ev);
        const hasModifier = ev.altKey || ev.ctrlKey || ev.metaKey || ev.shiftKey ||
            ev.key === "Alt" || ev.key === "Control" || ev.key === "Meta" || ev.key === "Shift";

        switch (ev.keyCode) {
            case KeyCode.PAGE_UP:
            case KeyCode.PAGE_DOWN:
                if (!hasModifier) {
                    this.onScrollKeyPressed(ev);
                    handled = true;
                }
                break;

            case KeyCode.HOME:
            case KeyCode.END:
                if (ev.ctrlKey && !ev.shiftKey && !ev.altKey && !ev.metaKey) {
                    this.onScrollKeyPressed(ev);
                    handled = true;
                }
                break;
            case KeyCode.KEY_K:
                if (ctrlCmdOnly) {
                    dis.dispatch({
                        action: 'focus_room_filter',
                    });
                    handled = true;
                }
                break;
            case KeyCode.KEY_BACKTICK:
                if (ctrlCmdOnly) {
                    dis.dispatch({
                        action: 'toggle_top_left_menu',
                    });
                    handled = true;
                }
                break;
        }

        if (handled) {
            ev.stopPropagation();
            ev.preventDefault();
        } else if (!hasModifier) {
            const isClickShortcut = ev.target !== document.body &&
                (ev.key === "Space" || ev.key === "Enter");

            if (!isClickShortcut && !canElementReceiveInput(ev.target)) {
                dis.dispatch({ action: 'focus_composer' }, true);
                ev.stopPropagation();                
            }
        }
    }

    onScrollKeyPressed(ev) {
        if (this.refs.roomView) {
            this.refs.roomView.handleScrollKey(ev);
        }
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        const dest = result.destination.droppableId;

        if (dest === 'tag-panel-droppable') {
            const draggableId = result.draggableId.split(' ').pop();

            dis.dispatch(TagOrderActions.moveTag(
                this._matrixClient,
                draggableId,
                result.destination.index,
            ), true);
        } 
        else if (dest.startsWith('room-sub-list-droppable_')) {
            this.onRoomTileEndDrag(result);
        }
    }

    onRoomTileEndDrag(result) {
        let newTag = result.destination.droppableId.split('_')[1];
        let prevTag = result.source.droppableId.split('_')[1];
        if (newTag === 'undefined') newTag = undefined;
        if (prevTag === 'undefined') prevTag = undefined;

        const roomId = result.draggableId.split('_')[1];

        const oldIndex = result.source.index;
        const newIndex = result.destination.index;

        dis.dispatch(RoomListActions.tagRoom(
            this._matrixClient,
            this._matrixClient.getRoom(roomId),
            prevTag, newTag,
            oldIndex, newIndex,
        ), true);
    }

    onMouseDown(ev) {
        if (this.props.leftDisabled && this.props.rightDisabled) {
            const targetClasses = new Set(ev.target.className.split(' '));
            if (
                targetClasses.has('mx_MatrixChat') ||
                targetClasses.has('mx_MatrixChat_middlePanel') ||
                targetClasses.has('mx_RoomView')
            ) {
                this.setState({
                    mouseDown: {
                        x: ev.pageX,
                        y: ev.pageY,
                    },
                });
            }
        }
    }

    onMouseUp(ev) {
        if (!this.state.mouseDown) return;

        const deltaX = ev.pageX - this.state.mouseDown.x;
        const deltaY = ev.pageY - this.state.mouseDown.y;
        const distance = Math.sqrt((deltaX * deltaX) + (deltaY + deltaY));
        const maxRadius = 5; // People shouldn't be straying too far, hopefully

        if (distance < maxRadius) {            
            dis.dispatch({ action: 'close_settings' });
        }

        this.setState({ mouseDown: null });
    }

    setResizeContainerRef(div) {
        this.resizeContainer = div;
    }

    render() {
        const LeftPanel = sdk.getComponent('structures.LeftPanel');
        const RoomView = sdk.getComponent('structures.RoomView');
        const UserView = sdk.getComponent('structures.UserView');
        const HomePage = sdk.getComponent('structures.HomePage');
        const GroupView = sdk.getComponent('structures.GroupView');
        const MyGroups = sdk.getComponent('structures.MyGroups');
        const MatrixToolbar = sdk.getComponent('globals.MatrixToolbar');
        // const CookieBar = sdk.getComponent('globals.CookieBar');
        const NewVersionBar = sdk.getComponent('globals.NewVersionBar');
        const UpdateCheckBar = sdk.getComponent('globals.UpdateCheckBar');
        const PasswordNagBar = sdk.getComponent('globals.PasswordNagBar');
        const ServerLimitBar = sdk.getComponent('globals.ServerLimitBar');

        let pageElement;
        switch ( this.props.page_type ) {
            case PageTypes.RoomView:
                pageElement = <RoomView
                    ref='roomView'
                    autoJoin={this.props.autoJoin}
                    onRegistered={this.props.onRegistered}
                    thirdPartyInvite={this.props.thirdPartyInvite}
                    oobData={this.props.roomOobData}
                    viaServers={this.props.viaServers}
                    eventPixelOffset={this.props.initialEventPixelOffset}
                    key={this.props.currentRoomId || 'roomview'}
                    disabled={this.props.middleDisabled}
                    collapsedRhs={this.props.collapsedRhs}
                    ConferenceHandler={this.props.ConferenceHandler}
                    resizeNotifier={this.props.resizeNotifier}
                />;

                break;
            case PageTypes.MyGroups:
                pageElement = <MyGroups />;
                
                break;                
            case PageTypes.RoomDirectory:
                break;
            case PageTypes.HomePage:
                pageElement = <HomePage />;
                
                break;
            case PageTypes.UserView:
                pageElement = <UserView userId={this.props.currentUserId} />;

                break;
            case PageTypes.GroupView:
                pageElement = <GroupView
                    groupId={this.props.currentGroupId}
                    isNew={this.props.currentGroupIsNew}
                    collapsedRhs={this.props.collapsedRhs}
                />;

                break;
        } 

        const usageLimitEvent = this.state.serverNoticeEvents.find((e) => {
            return (
                e && e.getType() === 'm.room.message' &&
                e.getContent()['server_notice_type'] === 'm.server_notice.usage_limit_reached'
            );
        });

        let topBar;
        if (this.state.syncErrorData && this.state.syncErrorData.error.errcode === 'M_RESOURCE_LIMIT_EXCEEDED') {
            topBar = <ServerLimitBar kind='hard'
                adminContact={this.state.syncErrorData.error.data.admin_contact}
                limitType={this.state.syncErrorData.error.data.limit_type}
            />;
        } 
        else if (usageLimitEvent) {
            topBar = <ServerLimitBar kind='soft'
                adminContact={usageLimitEvent.getContent().admin_contact}
                limitType={usageLimitEvent.getContent().limit_type}
            />;
        } 
        /*else if (this.props.showCookieBar && this.props.config.piwik) {
            const policyUrl = this.props.config.piwik.policyUrl || null;
            topBar = <CookieBar policyUrl={policyUrl} />;
        }*/
        else if (this.props.hasNewVersion) {
            topBar = <NewVersionBar version={this.props.version} newVersion={this.props.newVersion}
                releaseNotes={this.props.newVersionReleaseNotes}
            />;
        } 
        else if (this.props.checkingForUpdate) {
            topBar = <UpdateCheckBar {...this.props.checkingForUpdate} />;
        } 
        else if (this.state.userHasGeneratedPassword) {
            topBar = <PasswordNagBar />;
        } 
        else if (this.props.showNotifierToolbar) {
            topBar = <MatrixToolbar />;
        }        

        let bodyClasses = 'mx_MatrixChat';
        if (topBar) {
            bodyClasses += ' mx_MatrixChat_toolbarShowing';
        }
        if (this.state.useCompactLayout) {
            bodyClasses += ' mx_MatrixChat_useCompactLayout';
        }

        return (
            <div onPaste={this.onPaste} onKeyDown={this.onReactKeyDown} className='mx_MatrixChat_wrapper' aria-hidden={this.props.hideToSRUsers} onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp}>
                { topBar }
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <div ref={this.setResizeContainerRef} className={bodyClasses}>
                        <LeftPanel
                            resizeNotifier={ this.props.resizeNotifier }
                            collapsed={ this.props.collapseLhs || false }
                            disabled={ this.props.leftDisabled } />
                        <ResizeHandle />
                        { pageElement }
                    </div>
                </DragDropContext>
            </div>
        );
    }
}