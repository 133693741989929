'use strict';

import React from 'react';
import LeftPanel from 'matrix-react-sdk/lib/components/structures/LeftPanel';

import classNames from 'classnames';

import sdk from 'matrix-react-sdk/lib/index';
import dis from 'matrix-react-sdk/lib/dispatcher';
import VectorConferenceHandler from 'matrix-react-sdk/lib/VectorConferenceHandler';
import VectorTagPanelButtons from './VectorTagPanelButtons';
import SettingsStore from 'matrix-react-sdk/lib/settings/SettingsStore';
import { _t } from 'matrix-react-sdk/lib/languageHandler';

class VectorLeftPanel extends LeftPanel {
    static displayName = 'VectorLeftPanel';
    static replaces = 'LeftPanel';

    render() {
        const RoomList = sdk.getComponent('rooms.RoomList');
        const RoomBreadcrumbs = sdk.getComponent('rooms.RoomBreadcrumbs');
        const TagPanel = sdk.getComponent('structures.TagPanel');
        const CustomRoomTagPanel = sdk.getComponent('structures.CustomRoomTagPanel');
        const TopLeftMenuButton = sdk.getComponent('structures.TopLeftMenuButton');
        const SearchBox = sdk.getComponent('structures.SearchBox');
        const CallPreview = sdk.getComponent('voip.CallPreview');
        const AccessibleButton = sdk.getComponent('elements.AccessibleButton');

        const tagPanelEnabled = SettingsStore.getValue("TagPanel.enableTagPanel");
        let tagPanelContainer;

        const isCustomTagsEnabled = SettingsStore.isFeatureEnabled("feature_custom_tags");

        if (tagPanelEnabled) {
            tagPanelContainer = (<div className="mx_LeftPanel_tagPanelContainer">
                <TagPanel />
                {isCustomTagsEnabled ? <CustomRoomTagPanel /> : undefined}
                <VectorTagPanelButtons />
            </div>);
        }

        const containerClasses = classNames(
            "mx_LeftPanel_container", "mx_fadable",
            {
                "collapsed": this.props.collapsed,
                "mx_LeftPanel_container_hasTagPanel": tagPanelEnabled,
                "mx_fadable_faded": this.props.disabled,
            },
        );

        let exploreButton;
        if (!this.props.collapsed) {
            exploreButton = (
                <div className={classNames("mx_LeftPanel_explore", { "mx_LeftPanel_explore_hidden": this.state.searchExpanded })}>
                    <AccessibleButton onClick={() => dis.dispatch({ action: 'view_room_directory' })}>{_t("Explore")}</AccessibleButton>
                </div>
            );
        }

        const searchBox = (<SearchBox
            enableRoomSearchFocus={true}
            blurredPlaceholder={_t('Filter')}
            placeholder={_t('Filter rooms…')}
            onSearch={this.onSearch}
            onCleared={this.onSearchCleared}
            onFocus={this._onSearchFocus}
            onBlur={this._onSearchBlur}
            collapsed={this.props.collapsed} />);

        let breadcrumbs;
        if (this.state.breadcrumbs) {
            breadcrumbs = (<RoomBreadcrumbs collapsed={this.props.collapsed} />);
        }

        return (
            <div className={containerClasses}>
                { tagPanelContainer }
                <aside className={"mx_LeftPanel dark-panel"} onKeyDown={ this._onKeyDown } onFocus={ this._onFocus } onBlur={ this._onBlur }>
                    <TopLeftMenuButton collapsed={ this.props.collapsed } />
                    { breadcrumbs }
                    <div className="mx_LeftPanel_exploreAndFilterRow">
                        { exploreButton }
                        { searchBox }
                    </div>
                    <CallPreview ConferenceHandler={VectorConferenceHandler} />
                    <RoomList
                        ref={this.collectRoomList}
                        resizeNotifier={this.props.resizeNotifier}
                        collapsed={this.props.collapsed}
                        searchFilter={this.state.searchFilter}
                        ConferenceHandler={VectorConferenceHandler} />
                </aside>
            </div>
        );
    }
}

module.exports = VectorLeftPanel;