'use strict';

import React from 'react';
import Login from 'matrix-react-sdk/lib/components/structures/auth/Login';

import classNames from 'classnames';

import sdk from 'matrix-react-sdk/lib/index';
import SdkConfig from 'matrix-react-sdk/lib/SdkConfig';
import { _t, _td } from 'matrix-react-sdk/lib/languageHandler';

import request from 'browser-request';

const PHONE_NUMBER_REGEX = /^\d{10}$/;

const PHASE_LOGIN = 1;

const PHASES_ENABLED = true;

// These are used in several places, and come from the js-sdk's autodiscovery
// stuff. We define them here so that they'll be picked up by i18n.
_td("Invalid homeserver discovery response");
_td("Failed to get autodiscovery configuration from server");
_td("Invalid base_url for m.homeserver");
_td("Homeserver URL does not appear to be a valid Matrix homeserver");
_td("Invalid identity server discovery response");
_td("Invalid base_url for m.identity_server");
_td("Identity server URL does not appear to be a valid identity server");
_td("General failure");

export default class VectorLogin extends Login {
    static displayName = 'VectorLogin';
    static replaces = 'Login';

    constructor(props) {
        super(props);

        this.requestVerificationPIN = this.requestVerificationPIN.bind(this);
        this.onLoginTypeChanged = this.onLoginTypeChanged.bind(this);
    }

    getInitialState() {
        return {
            busy: false,
            errorText: null,
            loginIncorrect: false,
            canTryLogin: true,

            username: "",
            phoneCountry: null,
            phoneNumber: "",
            
            phase: PHASE_LOGIN,
            
            currentFlow: null,
            
            serverIsAlive: true,
            serverErrorIsFatal: false,
            serverDeadError: "",
            requestPIN: true
        }
    }    

    _renderLoginComponentForStep() {
        if (PHASES_ENABLED && this.state.phase !== PHASE_LOGIN) {
            return null;
        }

        const step = this.state.currentFlow;

        if (!step) {
            return null;
        }

        return this.renderPasswordStep();
    }

    renderPasswordStep() {
        const PasswordLogin = sdk.getComponent('auth.PasswordLogin');        

        return (            
            <PasswordLogin
                onSubmit={this.onPasswordLogin}
                onError={this.onPasswordLoginError}
                initialUsername={this.state.username}
                initialPhoneCountry={this.state.phoneCountry}
                initialPhoneNumber={this.state.phoneNumber}
                onUsernameChanged={this.onUsernameChanged}
                onUsernameBlur={this.onUsernameBlur}
                onPhoneCountryChanged={this.onPhoneCountryChanged}
                onPhoneNumberChanged={this.onPhoneNumberChanged}
                onPhoneNumberBlur={this.onPhoneNumberBlur}
                onForgotPasswordClick={this.props.onForgotPasswordClick}
                loginIncorrect={this.state.loginIncorrect}
                serverConfig={this.props.serverConfig}
                disableSubmit={this.isBusy()}
                requestPIN={this.state.requestPIN}
                requestVerificationPIN={this.requestVerificationPIN}
                onLoginTypeChanged={this.onLoginTypeChanged}
            />
        );
    }

    onLoginTypeChanged(loginType) {
        this.setState({
            requestPIN: loginType == "login_field_phone" ? true : false
        });
    }

    requestVerificationPIN(phonePrefix, phoneNumber) {
        this.setState({
            busy: true,
            errorText: "",
            loginIncorrect: false,
        });

        // Validate the phone number entered
        if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
            this.setState({
                busy: false,
                errorText: _t('The phone number entered looks invalid'),
                loginIncorrect: true,
            });

            return;
        }

        request({
            method: 'POST',
            url: SdkConfig.get().auth_pin_url,
            form: {
                phoneNumber: phonePrefix + "" + phoneNumber
            }
        }, (err, response, body) => {
            if ( err ) throw new Error(err);

            let JSONResponse = JSON.parse(body);

            this.setState({
                busy: false
            });

            if ( JSONResponse.success ) {
                this.setState({
                    requestPIN: false
                })
            }
            else {
                this.setState({
                    loginIncorrect: true,
                    errorText: JSONResponse.error
                })
            }
        });
    }

    render() {
        const Loader = sdk.getComponent("elements.Spinner");
        const AuthPage = sdk.getComponent("auth.AuthPage");
        const AuthHeader = sdk.getComponent("auth.AuthHeader");
        const AuthBody = sdk.getComponent("auth.AuthBody");
        const loader = this.isBusy() ? <div className="mx_Login_loader"><Loader /></div> : null;

        const errorText = this.state.errorText;

        let errorTextSection;
        if (errorText) {
            errorTextSection = (
                <div className="mx_Login_error">
                    { errorText }
                </div>
            );
        }

        let serverDeadSection;
        if (!this.state.serverIsAlive) {
            const classes = classNames({
                "mx_Login_error": true,
                "mx_Login_serverError": true,
                "mx_Login_serverErrorNonFatal": !this.state.serverErrorIsFatal,
            });
            serverDeadSection = (
                <div className={classes}>
                    {this.state.serverDeadError}
                </div>
            );
        }

        return (
            <AuthPage>
                <AuthHeader />
                <AuthBody>
                    <h2>
                        {_t('Welcome')}
                        {loader}
                    </h2>
                    { errorTextSection }
                    { serverDeadSection }
                    { this.renderServerComponent() }
                    { this._renderLoginComponentForStep() }
                    <a className="mx_AuthBody_changeFlow" onClick={this.onRegisterClick} href="#">
                        { _t('Create account') }
                    </a>
                </AuthBody>
            </AuthPage>            
        );
    }
}