import RoomView from 'matrix-react-sdk/lib/components/structures/RoomView';

export default class VectorRoomView extends RoomView {
    static displayName = 'VectorRoomView';
    static replaces = 'RoomView';
    
    onDragOver = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        
        if (ev.dataTransfer.types.indexOf('Files') >= 0) {
            this.setState({ draggingFile: true })
            ev.dataTransfer.dropEffect = 'copy';
        }
    }
}
