import sdk from 'matrix-react-sdk/lib/index';

import { COUNTRIES } from '../../../phonenumber';
import CountryDropdown from "matrix-react-sdk/lib/components/views/auth/CountryDropdown";

export default class VectorCountryDropdown extends CountryDropdown {
    static replaces = "CountryDropdown";

    render () {
        const Dropdown = sdk.getComponent('elements.Dropdown');

        let displayedCountries;
        if (this.state.searchQuery) {
            displayedCountries = COUNTRIES.filter(
                countryMatchesSearchQuery.bind(this, this.state.searchQuery),
            );

            if (
                this.state.searchQuery.length == 2 &&
                COUNTRIES_BY_ISO2[this.state.searchQuery.toUpperCase()]
            ) {
                // exact ISO2 country name match: make the first result the matches ISO2
                const matched = COUNTRIES_BY_ISO2[this.state.searchQuery.toUpperCase()];
                displayedCountries = displayedCountries.filter((c) => {
                    return c.iso2 != matched.iso2;
                });
                displayedCountries.unshift(matched);
            }
        } 
        else {
            displayedCountries = COUNTRIES;
        }

        const options = displayedCountries.map((country) => {
            return <div className="mx_CountryDropdown_option" key={country.iso2}>
                {this._flagImgForIso2(country.iso2)}
                {country.name} (+{country.prefix})
            </div>;
        });

        const value = this.props.value || this.state.defaultCountry.iso2;

        return <Dropdown className={this.props.className + " mx_CountryDropdown"}
                onOptionChange={this._onOptionChange} onSearchChange={this._onSearchChange}
                menuWidth={298} getShortOption={this._getShortOption}
                value={value} searchEnabled={true} disabled={this.props.disabled}>
            {options}
        </Dropdown>;
    }
}