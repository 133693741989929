'use strict';

import CookieBar from 'matrix-react-sdk/lib/components/views/globals/CookieBar';

export default class VectorCookieBar extends CookieBar {
    static displayName = 'VectorCookieBar';
    static replaces = 'CookieBar';

    render() {
        return (<div></div>);
    }
}

