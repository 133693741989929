import PropTypes from 'prop-types';

import { _t } from 'matrix-react-sdk/lib/languageHandler';
import * as languageHandler from 'matrix-react-sdk/lib/languageHandler';
import { SettingLevel } from "matrix-react-sdk/lib/settings/SettingsStore";
import SettingsStore from "matrix-react-sdk/lib/settings/SettingsStore";
import MatrixClientPeg from 'matrix-react-sdk/lib/MatrixClientPeg';
import sdk from "matrix-react-sdk/lib/index";
import dis from "matrix-react-sdk/lib/dispatcher";

import GeneralUserSettingsTab from 'matrix-react-sdk/lib/components/views/settings/tabs/user/GeneralUserSettingsTab';
import { getThreepidsWithBindStatus } from '../../../../../boundThreepids';

export default class VectorGeneralUserSettingsTab extends GeneralUserSettingsTab {
    static replaces = "GeneralUserSettingsTab";

    static propTypes = {
        closeSettingsFn: PropTypes.func.isRequired,
    };

    constructor() {
        super();

        this.state = {
            language: languageHandler.getCurrentLanguage(),
            theme: SettingsStore.getValueAt(SettingLevel.ACCOUNT, "theme"),
            haveIdServer: Boolean(MatrixClientPeg.get().getIdentityServerUrl()),
            serverSupportsSeparateAddAndBind: null,
            idServerHasUnsignedTerms: false,
            requiredPolicyInfo: {
                hasTerms: false,
            },
            emails: [],
            msisdns: [],
        };

        this.dispatcherRef = dis.register(this._onAction);
    }

    _getThreepidState = async () => {
        const cli = MatrixClientPeg.get();

        const threepids = await getThreepidsWithBindStatus(cli);
        this.setState({ emails: threepids.filter((a) => a.medium === 'email') });
        this.setState({ msisdns: threepids.filter((a) => a.medium === 'msisdn') });
    }

    _renderProfileSection = () => {
        const ProfileSettings = sdk.getComponent("views.settings.ProfileSettings");

        return (
            <div className="mx_SettingsTab_section">
                <span className="mx_SettingsTab_subheading">{ _t("Profile") }</span>
                <ProfileSettings />
            </div>
        );
    }

    _renderAccountSection = () => {
        const ChangePassword = sdk.getComponent("views.settings.ChangePassword");
        const EmailAddresses = sdk.getComponent("views.settings.account.EmailAddresses");
        const PhoneNumbers = sdk.getComponent("views.settings.account.PhoneNumbers");
        const Spinner = sdk.getComponent("views.elements.Spinner");
        
        const passwordChangeForm = (
            <ChangePassword
                className="mx_GeneralUserSettingsTab_changePassword"
                rowClassName=""
                buttonKind="primary"
                onError={ this._onPasswordChangeError }
                onFinished={ this._onPasswordChanged } />
        );

        let threepidSection = null;

        if (this.state.haveIdServer || this.state.serverSupportsSeparateAddAndBind === true) {
            threepidSection = <div>
                <span className="mx_SettingsTab_subheading">{_t("Email addresses")}</span>
                <EmailAddresses
                    emails={this.state.emails}
                    onEmailsChange={this._onEmailsChange}
                />

                <span className="mx_SettingsTab_subheading">{_t("Phone numbers")}</span>
                <PhoneNumbers
                    msisdns={this.state.msisdns}
                    onMsisdnsChange={this._onMsisdnsChange}
                />
            </div>;
        }
        else if(this.state.serverSupportsSeparateAddAndBind === null) {
            threepidSection = <Spinner />;
        }
        
        return (
            <div className="mx_SettingsTab_section mx_GeneralUserSettingsTab_accountSection">
                <span className="mx_SettingsTab_subheading">{_t("Account")}</span>
                <p className="mx_SettingsTab_subsectionText">
                    {_t("Set a new account password...")}
                </p>
                { passwordChangeForm }
                { threepidSection }
            </div>
        );
    }

    render() {
        return (
            <div className="mx_SettingsTab">
                <div className="mx_SettingsTab_heading">{_t("General")}</div>
                { this._renderProfileSection() }

                { this._renderAccountSection() }

                { this._renderLanguageSection() }
                { this._renderThemeSection() }

                <div className="mx_SettingsTab_heading">{_t("Deactivate account")}</div>
                { this._renderManagementSection() }
            </div>
        );
    }
}