'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import sdk from 'matrix-react-sdk/lib/index';

import RegistrationForm from 'matrix-react-sdk/lib/components/views/auth/RegistrationForm';
import { _t } from 'matrix-react-sdk/lib/languageHandler';
import withValidation from 'matrix-react-sdk/lib/components/views/elements/Validation'

const PHONE_NUMBER_REGEX = /^\d{10}$/;

const FIELD_FULLNAME = 'field_fullname';
const FIELD_EMAIL = 'field_email';
const FIELD_PHONE_NUMBER = 'field_phone_number';

export default class VectorRegistrationForm extends RegistrationForm {
    static displayName = 'VectorRegistrationForm';
    static replaces    = "RegistrationForm";

    static propTypes = {
        requestPIN: PropTypes.bool,
        requestVerificationPIN: PropTypes.func,
        doLogin: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            fieldValid: {},            
            phoneCountry: this.props.defaultPhoneCountry,
            username: this.props.defaultUsername || "",
            email: this.props.defaultEmail || "",
            phoneNumber: this.props.defaultPhoneNumber || "",
            password: this.props.defaultPassword || "",
            passwordConfirm: "",
            passwordComplexity: null,
            passwordSafe: false,
            fullname: this.props.defaultFullname || "",
            pin: ""
        };

        this._onSubmit                 = this._onSubmit.bind(this);
        this.doSubmit                  = this.doSubmit.bind(this);
        this._verifyFieldsBeforeSubmit = this._verifyFieldsBeforeSubmit.bind(this);
        this.renderFullName            = this.renderFullName.bind(this);
        this.onFullnameChange          = this.onFullnameChange.bind(this);        
        this.onFullnameValidate        = this.onFullnameValidate.bind(this);      
        
        this.onRequestPIN = this.onRequestPIN.bind(this);
        this.onPinChanged = this.onPinChanged.bind(this);

        this._doLogin = this._doLogin.bind(this);
    }

    onRequestPIN() {
        if (!this.props.requestPIN ){
            return ;
        }

        this.props.requestVerificationPIN(this.state.phonePrefix, this.state.phoneNumber);
    }

    onPinChanged(ev) {
        this.setState({
            pin: ev.target.value
        });
    }

    async _doLogin(ev) {
        ev.preventDefault();

        this.props.doLogin( this.state.pin );
    }

    async _onSubmit(ev) {
        ev.preventDefault();

        if (!this.props.canSubmit) return;

        const allFieldsValid = await this._verifyFieldsBeforeSubmit();
        if (!allFieldsValid) {            
            return;
        }
        
        this.doSubmit(ev);
    }

    doSubmit(ev) {
        const email = this.state.email.trim();

        const promise = this.props.onRegisterClick({
            email: email,
            phoneCountry: this.state.phoneCountry,
            phonePrefix: this.state.phonePrefix,
            phoneNumber: this.state.phoneNumber,
            fullName: this.state.fullname.trim()
        });

        if (promise) {
            ev.target.disabled = true;
            promise.finally(function () {
                ev.target.disabled = false;
            });
        }
    }

    async _verifyFieldsBeforeSubmit() {
        const activeElement = document.activeElement;
        if (activeElement) {
            activeElement.blur();
        }

        const fieldIDsInDisplayOrder = [
            FIELD_FULLNAME,
            FIELD_EMAIL,
            FIELD_PHONE_NUMBER
        ];
        
        for (const fieldID of fieldIDsInDisplayOrder) {
            const field = this[fieldID];
            if (!field) {
                continue;
            }

            await field.validate({ allowEmpty: false });
        }

        await new Promise(resolve => this.setState({}, resolve));

        if (this.allFieldsValid()) {
            return true;
        }

        const invalidField = this.findFirstInvalidField(fieldIDsInDisplayOrder);

        if (!invalidField) {
            return true;
        }

        invalidField.focus();
        invalidField.validate({ allowEmpty: false, focused: true });
        return false;
    }

    renderFullName() {
        const Field = sdk.getComponent('elements.Field');
        const fullNamePlaceholder = _t("Full name");

        return <Field 
                id="mx_RegistrationForm_fullname"
                ref={field => this[FIELD_FULLNAME] = field}
                type="text"
                label={ fullNamePlaceholder }
                value={ this.state.fullname }
                onChange={ this.onFullnameChange }
                onValidate={ this.onFullnameValidate }
                disabled={ this.props.requestPIN }
            />
    }

    onFullnameChange(ev) {
        this.setState({
            fullname: ev.target.value
        })
    }

    async onFullnameValidate(fieldState) {
        const result = await this.validateFullnameRules(fieldState);
        this.markFieldValid(FIELD_FULLNAME, result.valid);
        return result;
    }

    validateFullnameRules = withValidation ({
        rules: [
            {
                key: "required",
                test: ({ value, allowEmpty }) => allowEmpty || !!value,
                invalid: () => _t("Enter fullname"), 
            }
        ]
    })

    _renderEmail() {
        if (!this._showEmail()) {
            return null;
        }
        const Field = sdk.getComponent('elements.Field');
        const emailPlaceholder = this._authStepIsRequired('m.login.email.identity') ?
            _t("Email") :
            _t("Email (optional)");

        return <Field
            id="mx_RegistrationForm_email"
            className="mx_RegistrationForm_email"
            ref={ field => this[FIELD_EMAIL] = field }
            type="text"
            label={ emailPlaceholder }
            value={ this.state.email }
            onChange={ this.onEmailChange }
            onValidate={ this.onEmailValidate }
            disabled={ this.props.requestPIN }
        />;
    }

    _renderPhoneNumber() {
        if ( !this._showPhoneNumber() ) {
            return null;
        }
        
        const CountryDropdown = sdk.getComponent('views.auth.CountryDropdown');
        const Field = sdk.getComponent('elements.Field');
        const phoneLabel = _t("Phone");

        const phoneCountry = <CountryDropdown
            value={ this.state.phoneCountry }
            isSmall={ true }
            showPrefix={ true }
            onOptionChange={ this.onPhoneCountryChange }
            disabled={ this.props.requestPIN }
        />;

        return <Field
            id="mx_RegistrationForm_phoneNumber"
            ref={ field => this[FIELD_PHONE_NUMBER] = field }
            type="text"
            label={ phoneLabel }
            value={ this.state.phoneNumber }
            prefix={ phoneCountry }
            onChange={ this.onPhoneNumberChange }
            onValidate={ this.onPhoneNumberValidate }
            disabled={ this.props.requestPIN }
        />;
    }

    async onPhoneNumberValidate(fieldState) {
        const result = await this.validatePhoneNumberRules(fieldState);
        this.markFieldValid(FIELD_PHONE_NUMBER, result.valid);
        return result;
    }

    validatePhoneNumberRules = withValidation({
        rules: [
            {
                key: "required",
                test: ({ value, allowEmpty }) => allowEmpty || !!value,
                invalid: () => _t("Enter phone number"),
            },
            {
                key: "phone",
                test: ({ value }) => !value || PHONE_NUMBER_REGEX.test(value),
                invalid: () => _t("Doesn't look like a valid phone number"),
            },
        ]
    })

    render() {
        const Field = sdk.getComponent('elements.Field');

        let registerButton = null;
        if ( this.props.requestPIN ) {
            registerButton = (
                <input className="mx_Login_submit" type="submit" value={_t("Sign in")} disabled={!this.props.canSubmit} />
            );
        }
        else {
            registerButton = (
                <input className="mx_Login_submit" type="submit" value={_t("Register")} disabled={!this.props.canSubmit} />
            );
        }

        let emailHelperText = null;
        if (this._showEmail()) {
            emailHelperText = <div className="mx_AuthBody_fieldRow">{ _t("Use email to optionally be discoverable by existing contacts.") }</div>;
        }

        let pin;
        if ( this.props.requestPIN ) {
            pin = (<div className="mx_AuthBody_fieldRow">
                <Field
                    id=""
                    type="text"
                    name="pin"
                    label={ _t("Pin") }
                    value={ this.state.pin }
                    onChange={ this.onPinChanged }
                />
                <div className="break-line"></div>
                <a className="mx_request_pin"
                    href="#"
                    onClick={this.onRequestPIN}>
                    {_t("Request PIN again")}
                </a>
            </div>);
        }

        return (
            <div>                
                <form onSubmit={ this.props.requestPIN ? this._doLogin : this._onSubmit }>
                    <div className="mx_AuthBody_fieldRow">
                        { this.renderFullName() }
                    </div>
                    <div className="mx_AuthBody_fieldRow">
                        { this._renderEmail() }
                    </div>
                    { emailHelperText }
                    <div className="mx_AuthBody_fieldRow">
                        { this._renderPhoneNumber() }
                    </div>
                    { pin }
                    { registerButton } 
                </form>
            </div>
        );
    }
}