'use strict';

import React from 'react';
import TagPanelButtons from 'matrix-react-sdk/lib/components/structures/TagPanelButtons';
import sdk from 'matrix-react-sdk/lib/index';

import { _t } from 'matrix-react-sdk/lib/languageHandler';

class VectorTagPanelButtons extends TagPanelButtons {
    static displayName = 'VectorTagPanelButtons';
    static replaces = 'TagPanelButtons';

    render() {
        const ActionButton = sdk.getComponent("elements.ActionButton");
        const GroupsButton = sdk.getComponent('elements.GroupsButton');

        return (<div className="mx_TagPanelButtons">
            <ActionButton
                className="mx_HomePageButton" action="view_home_page"
                label={_t("Home")} tooltip={false} />
            <GroupsButton />
        </div>);
    }
}

module.exports = VectorTagPanelButtons;