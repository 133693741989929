'use strict';

import React from 'react';
import classNames from 'classnames';
import sdk from 'matrix-react-sdk/lib/index';

import { _t } from 'matrix-react-sdk/lib/languageHandler';
import MatrixClientPeg from 'matrix-react-sdk/lib/MatrixClientPeg';

import AccessibleButton from 'matrix-react-sdk/lib/components/views/elements/AccessibleButton';
import { CancelButton } from 'matrix-react-sdk/lib/components/views/rooms/SimpleRoomHeader';
import SettingsStore from "matrix-react-sdk/lib/settings/SettingsStore";
import RoomHeaderButtons from 'matrix-react-sdk/lib/components/views/right_panel/RoomHeaderButtons';
import E2EIcon from 'matrix-react-sdk/lib/components/views/rooms/E2EIcon';

export default class VectorRoomHeader extends React.PureComponent {
    static replaces = 'RoomHeader'

    render() {
        const RoomAvatar = sdk.getComponent("avatars.RoomAvatar");

        let searchStatus = null;
        let cancelButton = null;
        let settingsButton = null;
        let pinnedEventsButton = null;

        const e2eIcon = this.props.e2eStatus ?
            <E2EIcon status={this.props.e2eStatus} /> :
            undefined;

        if (this.props.onCancelClick) {
            cancelButton = <CancelButton onClick={this.props.onCancelClick} />;
        }
        
        if (this.props.searchInfo &&
            this.props.searchInfo.searchCount !== undefined &&
            this.props.searchInfo.searchCount !== null) {
            searchStatus = <div className="mx_RoomHeader_searchStatus">&nbsp;
                {_t("(~%(count)s results)", { count: this.props.searchInfo.searchCount })}
            </div>;
        }

        // XXX: this is a bit inefficient - we could just compare room.name for 'Empty room'...
        let settingsHint = false;
        const members = this.props.room ? this.props.room.getJoinedMembers() : undefined;
        if (members) {
            if (members.length === 1 && members[0].userId === MatrixClientPeg.get().credentials.userId) {
                const nameEvent = this.props.room.currentState.getStateEvents('m.room.name', '');
                if (!nameEvent || !nameEvent.getContent().name) {
                    settingsHint = true;
                }
            }
        }

        let roomName = _t("Join Room");
        if (this.props.oobData && this.props.oobData.name) {
            roomName = this.props.oobData.name;
        } else if (this.props.room) {
            roomName = this.props.room.name;
        }

        const textClasses = classNames('mx_RoomHeader_nametext', { mx_RoomHeader_settingsHint: settingsHint });
        const name =
            <div className="mx_RoomHeader_name" onClick={this.props.onSettingsClick}>
                <div dir="auto" className={textClasses} title={roomName}>{roomName}</div>
                {searchStatus}
            </div>;

        let topic;
        if (this.props.room) {
            const ev = this.props.room.currentState.getStateEvents('m.room.topic', '');
            if (ev) {
                topic = ev.getContent().topic;
            }
        }
        const topicElement = <div className="mx_RoomHeader_topic" ref="topic" title={topic} dir="auto">{topic}</div>;
        
        const avatarSize = 28;
        let roomAvatar;
        if (this.props.room) {
            roomAvatar = (<RoomAvatar
                room={this.props.room}
                width={avatarSize}
                height={avatarSize}
                oobData={this.props.oobData}
                viewAvatarOnClick={true} />);
        }

        if (this.props.onSettingsClick) {
            settingsButton =
                <AccessibleButton className="mx_RoomHeader_button mx_RoomHeader_settingsButton"
                    onClick={this.props.onSettingsClick}
                    title={_t("Settings")}
                >
                </AccessibleButton>;
        }

        if (this.props.onPinnedClick && SettingsStore.isFeatureEnabled('feature_pinning')) {
            let pinsIndicator = null;
            if (this._hasUnreadPins()) {
                pinsIndicator = (<div className="mx_RoomHeader_pinsIndicator mx_RoomHeader_pinsIndicatorUnread" />);
            } else if (this._hasPins()) {
                pinsIndicator = (<div className="mx_RoomHeader_pinsIndicator" />);
            }

            pinnedEventsButton =
                <AccessibleButton className="mx_RoomHeader_button mx_RoomHeader_pinnedButton"
                    onClick={this.props.onPinnedClick} title={_t("Pinned Messages")}>
                    {pinsIndicator}
                </AccessibleButton>;
        }

        let forgetButton;
        if (this.props.onForgetClick) {
            forgetButton =
                <AccessibleButton className="mx_RoomHeader_button mx_RoomHeader_forgetButton"
                    onClick={this.props.onForgetClick}
                    title={_t("Forget room")}
                >
                </AccessibleButton>;
        }

        let searchButton;
        if (this.props.onSearchClick && this.props.inRoom) {
            searchButton =
                <AccessibleButton className="mx_RoomHeader_button mx_RoomHeader_searchButton"
                    onClick={this.props.onSearchClick}
                    title={_t("Search")}
                >
                </AccessibleButton>;
        }        

        const rightRow =
            <div className="mx_RoomHeader_buttons">
                {settingsButton}
                {pinnedEventsButton}
                {forgetButton}
                {searchButton}
            </div>;

        return (
            <div className="mx_RoomHeader light-panel">
                <div className="mx_RoomHeader_wrapper">
                    <div className="mx_RoomHeader_avatar">{roomAvatar}</div>
                    {e2eIcon}
                    {name}
                    {topicElement}
                    {cancelButton}
                    {rightRow}
                    <RoomHeaderButtons collapsedRhs={this.props.collapsedRhs} />
                </div>
            </div>
        );
    }
}