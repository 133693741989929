/*
Copyright 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

/*
 * THIS FILE IS AUTO-GENERATED
 * You can edit it you like, but your changes will be overwritten,
 * so you'd just be trying to swim upstream like a salmon.
 * You are not a salmon.
 */

let components = require('matrix-react-sdk/lib/component-index').components;
if (!components) {
    throw new Error("'matrix-react-sdk/lib/component-index' didn't export components");
}
import structures$HomePage from './components/structures/HomePage';
structures$HomePage && (components['structures.HomePage'] = structures$HomePage);
import structures$VectorEmbeddedPage from './components/structures/VectorEmbeddedPage';
structures$VectorEmbeddedPage && (components['structures.VectorEmbeddedPage'] = structures$VectorEmbeddedPage);
import structures$VectorGroupView from './components/structures/VectorGroupView';
structures$VectorGroupView && (components['structures.VectorGroupView'] = structures$VectorGroupView);
import structures$VectorLeftPanel from './components/structures/VectorLeftPanel';
structures$VectorLeftPanel && (components['structures.VectorLeftPanel'] = structures$VectorLeftPanel);
import structures$VectorLoggedInView from './components/structures/VectorLoggedInView';
structures$VectorLoggedInView && (components['structures.VectorLoggedInView'] = structures$VectorLoggedInView);
import structures$VectorRoomView from './components/structures/VectorRoomView';
structures$VectorRoomView && (components['structures.VectorRoomView'] = structures$VectorRoomView);
import structures$VectorTagPanelButtons from './components/structures/VectorTagPanelButtons';
structures$VectorTagPanelButtons && (components['structures.VectorTagPanelButtons'] = structures$VectorTagPanelButtons);
import structures$auth$Login from './components/structures/auth/Login';
structures$auth$Login && (components['structures.auth.Login'] = structures$auth$Login);
import structures$auth$Registration from './components/structures/auth/Registration';
structures$auth$Registration && (components['structures.auth.Registration'] = structures$auth$Registration);
import views$auth$VectorAuthFooter from './components/views/auth/VectorAuthFooter';
views$auth$VectorAuthFooter && (components['views.auth.VectorAuthFooter'] = views$auth$VectorAuthFooter);
import views$auth$VectorAuthHeaderLogo from './components/views/auth/VectorAuthHeaderLogo';
views$auth$VectorAuthHeaderLogo && (components['views.auth.VectorAuthHeaderLogo'] = views$auth$VectorAuthHeaderLogo);
import views$auth$VectorAuthPage from './components/views/auth/VectorAuthPage';
views$auth$VectorAuthPage && (components['views.auth.VectorAuthPage'] = views$auth$VectorAuthPage);
import views$auth$VectorCountryDropdown from './components/views/auth/VectorCountryDropdown';
views$auth$VectorCountryDropdown && (components['views.auth.VectorCountryDropdown'] = views$auth$VectorCountryDropdown);
import views$auth$VectorCustomServerDialog from './components/views/auth/VectorCustomServerDialog';
views$auth$VectorCustomServerDialog && (components['views.auth.VectorCustomServerDialog'] = views$auth$VectorCustomServerDialog);
import views$auth$VectorPasswordLogin from './components/views/auth/VectorPasswordLogin';
views$auth$VectorPasswordLogin && (components['views.auth.VectorPasswordLogin'] = views$auth$VectorPasswordLogin);
import views$auth$VectorRegistrationForm from './components/views/auth/VectorRegistrationForm';
views$auth$VectorRegistrationForm && (components['views.auth.VectorRegistrationForm'] = views$auth$VectorRegistrationForm);
import views$dialogs$VectorUserSettingsDailog from './components/views/dialogs/VectorUserSettingsDailog';
views$dialogs$VectorUserSettingsDailog && (components['views.dialogs.VectorUserSettingsDailog'] = views$dialogs$VectorUserSettingsDailog);
import views$directory$VectorNetworkDropdown from './components/views/directory/VectorNetworkDropdown';
views$directory$VectorNetworkDropdown && (components['views.directory.VectorNetworkDropdown'] = views$directory$VectorNetworkDropdown);
import views$globals$CookieBar from './components/views/globals/CookieBar';
views$globals$CookieBar && (components['views.globals.CookieBar'] = views$globals$CookieBar);
import views$rooms$VectorRoomHeader from './components/views/rooms/VectorRoomHeader';
views$rooms$VectorRoomHeader && (components['views.rooms.VectorRoomHeader'] = views$rooms$VectorRoomHeader);
import views$rooms$VectorSlateMessageComposer from './components/views/rooms/VectorSlateMessageComposer';
views$rooms$VectorSlateMessageComposer && (components['views.rooms.VectorSlateMessageComposer'] = views$rooms$VectorSlateMessageComposer);
import views$rooms$VectorStickerpicker from './components/views/rooms/VectorStickerpicker';
views$rooms$VectorStickerpicker && (components['views.rooms.VectorStickerpicker'] = views$rooms$VectorStickerpicker);
import views$settings$VectorProfileSettings from './components/views/settings/VectorProfileSettings';
views$settings$VectorProfileSettings && (components['views.settings.VectorProfileSettings'] = views$settings$VectorProfileSettings);
import views$settings$account$VectorEmailAddresses from './components/views/settings/account/VectorEmailAddresses';
views$settings$account$VectorEmailAddresses && (components['views.settings.account.VectorEmailAddresses'] = views$settings$account$VectorEmailAddresses);
import views$settings$account$VectorPhoneNumbers from './components/views/settings/account/VectorPhoneNumbers';
views$settings$account$VectorPhoneNumbers && (components['views.settings.account.VectorPhoneNumbers'] = views$settings$account$VectorPhoneNumbers);
import views$settings$tabs$user$VectorGeneralUserSettingsTab from './components/views/settings/tabs/user/VectorGeneralUserSettingsTab';
views$settings$tabs$user$VectorGeneralUserSettingsTab && (components['views.settings.tabs.user.VectorGeneralUserSettingsTab'] = views$settings$tabs$user$VectorGeneralUserSettingsTab);
import views$settings$tabs$user$VectorHelpUserSettingsTab from './components/views/settings/tabs/user/VectorHelpUserSettingsTab';
views$settings$tabs$user$VectorHelpUserSettingsTab && (components['views.settings.tabs.user.VectorHelpUserSettingsTab'] = views$settings$tabs$user$VectorHelpUserSettingsTab);
export {components};
