'use strict';

import React from 'react';
import sdk from 'matrix-react-sdk/lib/index';
import SdkConfig from 'matrix-react-sdk/lib/SdkConfig';

import { _t } from 'matrix-react-sdk/lib/languageHandler';

import request from 'browser-request';

export default class HomePage extends React.Component {
    constructor(props) {
        super(props);

        const brandingConfig = SdkConfig.get().branding;
        this.state = {
            directory: null,
            locations: null,
            defaultLocation: 'MTY',
            api: brandingConfig.api_url,
        }
        
        this._getLocations = this._getLocations.bind(this);
        this._onLocationChange = this._onLocationChange.bind(this);
        this._renderLocations = this._renderLocations.bind(this);

        this._getDirectory = this._getDirectory.bind(this);
        this._renderDirectory = this._renderDirectory.bind(this);
    }

    componentDidMount() {
        console.debug("homepage will mount");
        
        this._getLocations();
        this._getDirectory();
    }
    
    async _getLocations() {
        request({
            method: 'GET',
            url: this.state.api +"/vgnet_locations"
        }, (err, response, body) => {
            if (err) throw new Error(err);

            if ( response.statusCode == 200 ) {                
                let JSONResponse = JSON.parse(body);
                
                this.setState({
                    locations: JSONResponse.locations
                });
            }
        });
    }

    _onLocationChange(newLocation) {
        this.setState({
            defaultLocation: newLocation
        });
    }

    _renderLocations() {
        const dropdown = sdk.getComponent("elements.Dropdown");

        let locations = this.state.locations.map(function (location) {
            return React.createElement(
                'div',
                { key: location.value },
                location.label
            );
        });

        return React.createElement(
            dropdown,
            {
                className: 'mx_Location_Dropdown',
                onOptionChange: this._onLocationChange,
                onSearchChange: this._onLocationChange,
                searchEnabled: false,
                value: this.state.defaultLocation
            },
            locations
        );
    }

    async _getDirectory() {
        request({
            method: 'GET',
            url: this.state.api + "/vgnet_contacts"
        }, (err, response, body) => {
            if (err) throw new Error(err);

            if (response.statusCode == 200) {
                let JSONResponse = JSON.parse(body);

                this.setState({
                    directory: JSONResponse.directory
                });
            }
        });
    }

    _renderDirectory() {
        return this.state.directory[ this.state.defaultLocation ].map(function (contact) {
            return React.createElement(
                'div',
                { className: 'mx_HomePage_room', key: contact.mxid },
                React.createElement(
                    'a',
                    { href: '#/user/' + contact.mxid + '?action=chat' },
                    React.createElement(
                        'img',
                        { className: 'mx_HomePage_icon', src: contact.profile_image },
                        null
                    )
                ),
                React.createElement(
                    'span',
                    { className: 'mx_HomePage_desc' },
                    contact.name
                )
            );
        });
    }

    render () {
        let directory;
        if ( this.state.locations != null ) {
            
            let locationsDropdown = this._renderLocations();            
            let contacts;
            if ( this.state.directory != null ) {
                contacts = this._renderDirectory();
            }

            directory = (
                <div className="mx_HomePage_section">
                    <h3 className="title-with-dropdown">{_t("Contact our sales executives directly in")}</h3>
                    { locationsDropdown }
                    <div className="mx_HomePage_row">
                        { contacts }
                    </div>
                </div>
            );
        }

        return (
            <main className="mx_HomePage">
                <div className="mx_HomePage_body">
                    
                    <div className="mx_HomePage_section">
                        <h3 className="title">{_t("Communities")}</h3>
                        <div className="mx_HomePage_row">
                            <div className="mx_HomePage_room">
                                <a href="#/group/+telecomvg:tvg.mx">
                                    <img className="mx_HomePage_icon" src="img/home/VG.png" />
                                </a>
                                <span className="mx_HomePage_desc"><a href="#/group/+telecomvg:tvg.mx">Telecomunicaciones VG</a></span>
                            </div>
                        </div>
                    </div>

                    { directory }
                </div>
            </main>
        );
    }
}