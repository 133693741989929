
import { Tab, TabbedView } from "matrix-react-sdk/lib/components/structures/TabbedView";
import sdk from "matrix-react-sdk/lib/index";
import SdkConfig from "matrix-react-sdk/lib/SdkConfig";
import { _t, _td } from 'matrix-react-sdk/lib/languageHandler';

import SettingsStore from "matrix-react-sdk/lib/settings/SettingsStore";
import UserSettingsDialog from 'matrix-react-sdk/lib/components/views/dialogs/UserSettingsDialog';

export default class VectorUserSettingsDialog extends UserSettingsDialog {
    static replaces = "UserSettingsDialog"

    _getTabs = () => {
        const GeneralUserSettingsTab = sdk.getComponent('views.settings.tabs.user.GeneralUserSettingsTab');        
        const LabsUserSettingsTab = sdk.getComponent('views.settings.tabs.user.LabsUserSettingsTab');
        const SecurityUserSettingsTab = sdk.getComponent('views.settings.tabs.user.SecurityUserSettingsTab');
        const NotificationUserSettingsTab = sdk.getComponent('views.settings.tabs.user.NotificationUserSettingsTab');
        const PreferencesUserSettingsTab = sdk.getComponent('views.settings.tabs.user.PreferencesUserSettingsTab');
        const VoiceUserSettingsTab = sdk.getComponent('views.settings.tabs.user.VoiceUserSettingsTab');        
        const HelpUserSettingsTab = sdk.getComponent('views.settings.tabs.user.HelpUserSettingsTab');
        const FlairUserSettingsTab = sdk.getComponent('views.settings.tabs.user.FlairUserSettingsTab');

        const tabs = [];

        tabs.push(new Tab(
            _td("General"),
            "mx_UserSettingsDialog_settingsIcon",
            <GeneralUserSettingsTab closeSettingsFn={this.props.onFinished} />,
        ));

        tabs.push(new Tab(
            _td("Flair"),
            "mx_UserSettingsDialog_flairIcon",
            <FlairUserSettingsTab />,
        ));

        tabs.push(new Tab(
            _td("Notifications"),
            "mx_UserSettingsDialog_bellIcon",
            <NotificationUserSettingsTab />,
        ));

        tabs.push(new Tab(
            _td("Preferences"),
            "mx_UserSettingsDialog_preferencesIcon",
            <PreferencesUserSettingsTab />,
        ));

        tabs.push(new Tab(
            _td("Voice & Video"),
            "mx_UserSettingsDialog_voiceIcon",
            <VoiceUserSettingsTab />,
        ));

        tabs.push(new Tab(
            _td("Security & Privacy"),
            "mx_UserSettingsDialog_securityIcon",
            <SecurityUserSettingsTab />,
        ));

        if (SdkConfig.get()['showLabsSettings'] || SettingsStore.getLabsFeatures().length > 0) {
            tabs.push(new Tab(
                _td("Labs"),
                "mx_UserSettingsDialog_labsIcon",
                <LabsUserSettingsTab />,
            ));
        }

        tabs.push(new Tab(
            _td("Help & About"),
            "mx_UserSettingsDialog_helpIcon",
            <HelpUserSettingsTab closeSettingsFn={this.props.onFinished} />,
        ));

        return tabs;
    }

    render() {
        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');

        return (
            <BaseDialog className='mx_UserSettingsDialog' hasCancel={true}
                onFinished={this.props.onFinished} title={_t("Settings")}>
                <div className='ms_SettingsDialog_content'>
                    <TabbedView tabs={this._getTabs()} />
                </div>
            </BaseDialog>
        );
    }
}