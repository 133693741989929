'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import sdk from 'matrix-react-sdk/lib/index';
import SdkConfig from 'matrix-react-sdk/lib/SdkConfig';

import PasswordLogin from 'matrix-react-sdk/lib/components/views/auth/PasswordLogin';
import { _t } from 'matrix-react-sdk/lib/languageHandler';

export default class VectorPasswordLogin extends PasswordLogin {
    static replaces = "PasswordLogin";

    static propTypes = {        
        // initialPassword: PropTypes.string,
        requestPIN: PropTypes.bool,
        requestVerificationPIN: PropTypes.func,
        onLoginTypeChanged: PropTypes.func,
    };

    static defaultProps = {
        onError: function() {},
        onEditServerDetailsClick: null,
        onUsernameChanged: function() {},
        onUsernameBlur: function() {},
        onPasswordChanged: function() {},
        onPhoneCountryChanged: function() {},
        onPhoneNumberChanged: function() {},
        onPhoneNumberBlur: function() {},
        initialUsername: "",
        initialPhoneCountry: "",
        initialPhoneNumber: "",
        initialPassword: "",
        loginIncorrect: false,
        disableSubmit: false,
        requestVerificationPIN: function(){},
        onLoginTypeChanged: function(){},
    };

    constructor(props) {
        super(props);
        
        this.state = {
            username: this.props.initialUsername,
            password: this.props.initialPassword,
            phoneCountry: this.props.initialPhoneCountry,
            phoneNumber: this.props.initialPhoneNumber,
            loginType: PasswordLogin.LOGIN_FIELD_PHONE,
            requestPIN: this.props.requestPIN
        };

        this.onRequestPIN = this.onRequestPIN.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            requestPIN: nextProps.requestPIN
        });
    }

    onSubmitForm(ev) {
        ev.preventDefault();

        let username = ''; // XXX: Synapse breaks if you send null here:
        let phoneCountry = null;
        let phoneNumber = null;
        let error;

        switch (this.state.loginType) {
            case PasswordLogin.LOGIN_FIELD_EMAIL:
                username = this.state.username;
                if (!username) {
                    error = _t('The email field must not be blank.');
                }
                break;
            case PasswordLogin.LOGIN_FIELD_MXID:
                username = this.state.username;
                if (!username) {
                    error = _t('The username field must not be blank.');
                }
                break;
            case PasswordLogin.LOGIN_FIELD_PHONE:
                phoneCountry = this.state.phoneCountry;
                phoneNumber = this.state.phoneNumber;
                if (!phoneNumber) {
                    error = _t('The phone number field must not be blank.');
                }
                break;
        }

        if (error) {
            this.props.onError(error);
            return;
        }

        if ( this.state.loginType == PasswordLogin.LOGIN_FIELD_PHONE && this.state.requestPIN ) {
            this.onRequestPIN();
            return;
        }

        if ( !this.state.password && !this.state.requestPIN ) {
            if ( this.state.loginType == PasswordLogin.LOGIN_FIELD_PHONE )
                this.props.onError( _t('The pin field must not be blank.') );
            else 
                this.props.onError( _t('The password field must not be blank.') );
            
            return;
        }

        this.props.onSubmit(
            username,
            phoneCountry,
            phoneNumber,
            this.state.password,
        );
    }

    onLoginTypeChange(ev) {
        const loginType = ev.target.value;
        this.props.onError(null); // send a null error to clear any error messages
        this.props.onLoginTypeChanged(loginType);
        
        this.setState({
            loginType: loginType,
            username: "", // Reset because email and username use the same state
        });
    }

    onRequestPIN() {            
        this.props.requestVerificationPIN( this.state.phonePrefix, this.state.phoneNumber );
    }

    render() {
        const Field = sdk.getComponent('elements.Field');

        const pwFieldClass = classNames({
            error: this.props.loginIncorrect && !this.isLoginEmpty(), // only error password if error isn't top field
        });

        const loginField = this.renderLoginField(this.state.loginType);

        let loginType;
        if (!SdkConfig.get().disable_3pid_login) {
            loginType = (
                <div className="mx_Login_type_container">
                    <label className="mx_Login_type_label">{_t('Sign in with')}</label>
                    <Field
                        id="mx_PasswordLogin_type"
                        element="select"
                        value={this.state.loginType}
                        onChange={this.onLoginTypeChange}>
                        <option
                            key={PasswordLogin.LOGIN_FIELD_MXID}
                            value={PasswordLogin.LOGIN_FIELD_MXID}
                        >
                            {_t('Username')}
                        </option>
                        <option
                            key={PasswordLogin.LOGIN_FIELD_EMAIL}
                            value={PasswordLogin.LOGIN_FIELD_EMAIL}
                        >
                            {_t('Email address')}
                        </option>
                        <option
                            key={PasswordLogin.LOGIN_FIELD_PHONE}
                            value={PasswordLogin.LOGIN_FIELD_PHONE}
                        >
                            {_t('Phone')}
                        </option>
                    </Field>
                </div>
            );
        }

        let passwordField;
        if ( this.state.loginType != PasswordLogin.LOGIN_FIELD_PHONE || ( this.state.loginType == PasswordLogin.LOGIN_FIELD_PHONE && !this.state.requestPIN ) ) {
            passwordField = <Field
                className={pwFieldClass}
                id="mx_PasswordLogin_password"
                type={ this.state.loginType == PasswordLogin.LOGIN_FIELD_PHONE ? "text" : "password" }
                name="password"
                label={ this.state.loginType == PasswordLogin.LOGIN_FIELD_PHONE ? _t('Pin') : _t('Password') }
                value={ this.state.password }
                onChange={ this.onPasswordChanged }
            />
        }

        let requestPIN;
        if ( this.state.loginType == PasswordLogin.LOGIN_FIELD_PHONE ) {
            requestPIN = <a className="mx_Login_request_pin"
                    href="#"
                    onClick={ this.onRequestPIN }>
                        { _t("Request PIN again") }
                    </a>;
        }

        let submitField;
        if ( !this.state.requestPIN ) {
            submitField = (
                    <div>
                        <input className="mx_Login_submit"
                            type="submit"
                            value={ _t('Sign in') }
                            disabled={ this.props.disableSubmit }
                        />
                        { requestPIN }
                    </div>                    
                );
        }
        else {
            submitField = <button
                className="mx_Login_submit"
                type="button"
                onClick={ this.onRequestPIN }
                disabled={ this.props.disableSubmit }>
                { _t('Request Access PIN') }
            </button>
        }

        return (
            <div>
                <form onSubmit={this.onSubmitForm}>
                    {loginType}
                    {loginField}
                    {passwordField}
                    {submitField}
                </form>
            </div>
        );
    }
}