'use strict';

import React from 'react';
import classNames from 'classnames';
import sdk from 'matrix-react-sdk/lib/index';

import AccessibleButton from 'matrix-react-sdk/lib/components/views/elements/AccessibleButton';

export default class VectorStickerpicker extends React.PureComponent {
    render() {
        let stickersButton;

        stickersButton = <AccessibleButton
            id='stickersButton'
            element="img"
            key="controls_show_stickers"
            className="mx_MessageComposer_button mx_MessageComposer_giphy"            
            src="themes/riot/img/gif-button.png"
            />

        return <div>
            {stickersButton}
        </div>
    };
}