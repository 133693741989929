import React from 'react';
import PropTypes from 'prop-types';

import { _t, _td } from 'matrix-react-sdk/lib/languageHandler';
import AccessibleButton from "matrix-react-sdk/lib/components/views/elements/AccessibleButton";
import Field from "matrix-react-sdk/lib/components/views/elements/Field";
import sdk from 'matrix-react-sdk/lib/index';
import Modal from 'matrix-react-sdk/lib/Modal';

import EmailAddresses from "matrix-react-sdk/lib/components/views/settings/account/EmailAddresses";

export class ExistingEmailAddress extends React.Component {
    static propTypes = {
        email: PropTypes.object.isRequired,
        onRemoved: PropTypes.func.isRequired,
    };

    constructor() {
        super();

        this.state = {
            verifyRemove: false,
        };
    }

    _onRemove = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({ verifyRemove: true });
    };

    _onDontRemove = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({ verifyRemove: false });
    };

    _onActuallyRemove = (e) => {
        e.stopPropagation();
        e.preventDefault();

        MatrixClientPeg.get().deleteThreePid(this.props.email.medium, this.props.email.address).then(() => {
            return this.props.onRemoved(this.props.email);
        }).catch((err) => {
            const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
            console.error("Unable to remove contact information: " + err);
            
            Modal.createTrackedDialog('Remove 3pid failed', '', ErrorDialog, {
                title: _t("Unable to remove contact information"),
                description: ((err && err.message) ? err.message : _t("Operation failed")),
            });
        });
    };

    render() {
        if (this.state.verifyRemove) {
            return (
                <div className="mx_ExistingEmailAddress">
                    <span className="mx_ExistingEmailAddress_promptText">
                        {_t("Remove %(email)s?", { email: this.props.email.address })}
                    </span>
                    <AccessibleButton onClick={this._onActuallyRemove} kind="danger_sm"
                        className="mx_ExistingEmailAddress_confirmBtn">
                        {_t("Remove")}
                    </AccessibleButton>
                    <AccessibleButton onClick={this._onDontRemove} kind="link_sm"
                        className="mx_ExistingEmailAddress_confirmBtn">
                        {_t("Cancel")}
                    </AccessibleButton>
                </div>
            );
        }

        return (
            <div className="mx_ExistingEmailAddress">
                <span className="mx_ExistingEmailAddress_email">{this.props.email.address}</span>
                <AccessibleButton onClick={this._onRemove} kind="danger_sm">
                    {_t("Remove")}
                </AccessibleButton>
            </div>
        );
    }
}

export default class VectorEmailAddresses extends EmailAddresses {
    static replaces = "EmailAddresses";

    render () {
        const existingEmailElements = this.props.emails.map((e) => {            
            return <ExistingEmailAddress email={e} onRemoved={this._onRemoved} key={e.address} />;
        });

        let addButton = (
            <AccessibleButton onClick={this._onAddClick} kind="primary">
                {_t("Add")}
            </AccessibleButton>
        );
        
        if (this.state.verifying) {
            addButton = (
                <div>
                    <div>{_t("We've sent you an email to verify your address. Please follow the instructions there and then click the button below.")}</div>
                    <AccessibleButton onClick={this._onContinueClick} kind="primary"
                        disabled={this.state.continueDisabled}>
                        {_t("Continue")}
                    </AccessibleButton>
                </div>
            );
        }

        return (
            <div className="mx_EmailAddresses">
                { existingEmailElements }
                <form onSubmit={ this._onAddClick } autoComplete="false"
                    noValidate={true} className="mx_EmailAddresses_new">
                    <Field id="mx_EmailAddressses_newEmailAddress"
                        type="text"
                        label={ _t("Email Address") }
                        autoComplete="off"
                        disabled={ this.state.verifying }
                        value={ this.state.newEmailAddress }
                        onChange={ this._onChangeNewEmailAddress }
                    />
                    { addButton }
                </form>
            </div>
        )
    }
}