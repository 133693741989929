'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Registration from 'matrix-react-sdk/lib/components/structures/auth/Registration';

import classNames from 'classnames';

import sdk from 'matrix-react-sdk/lib/index';
import SdkConfig from 'matrix-react-sdk/lib/SdkConfig';
import { _t, _td } from 'matrix-react-sdk/lib/languageHandler';

import * as ServerType from 'matrix-react-sdk/lib/components/views/auth/ServerTypeSelector';
import { ValidatedServerConfig } from "matrix-react-sdk/lib/utils/AutoDiscoveryUtils";

import request from 'browser-request';

const PHASE_REGISTRATION = 1;
const PHASES_ENABLED = true;

export default class VectorRegistration extends Registration {
    static displayName = 'VectorRegistration';
    static replaces = 'Registration';

    static propTypes = {
        onLoggedIn: PropTypes.func.isRequired,
        clientSecret: PropTypes.string,
        sessionId: PropTypes.string,
        makeRegistrationUrl: PropTypes.func.isRequired,
        idSid: PropTypes.string,
        serverConfig: PropTypes.instanceOf(ValidatedServerConfig).isRequired,
        brand: PropTypes.string,
        email: PropTypes.string,        
        onLoginClick: PropTypes.func.isRequired,
        onServerConfigChange: PropTypes.func.isRequired,
    }

    static defaultProps = {
        defaultEmail: "",
        defaultPassword: "",
        defaultPhoneCountry: "",
        defaultPhoneNumber: ""
    }

    constructor(props) {
        super(props);

        const serverType = ServerType.getTypeFromServerConfig(this.props.serverConfig);

        this.state = {
            busy: false,
            errorText: null,
            formVals: {
                email: this.props.defaultEmail
            },
            doingUIAuth: Boolean(this.props.sessionId),
            serverType,
            phase: PHASE_REGISTRATION,
            flows: null,
            completedNoSignin: false,
            serverIsAlive: true,
            serverErrorIsFatal: false,
            serverDeadError: "",
            matrixClient: null,
            serverRequiresIdServer: null,
            registeredUsername: null,
            differentLoggedInUserId: null,
            requestPIN: false,
            mxid_registered: null
        };

        this._onFormSubmit          = this._onFormSubmit.bind(this);
        this.doLogin                = this.doLogin.bind(this);
        this.requestVerificationPIN = this.requestVerificationPIN.bind(this);
    }

    _onFormSubmit( formVals ) {
        this.setState({
            errorText: "",
            busy: true,
            formVals: formVals
        });

        request({
            method: 'POST',
            url: SdkConfig.get().register_url,
            form: formVals
        }, (err, response, body) => {
            if (err) throw new Error(err);
            
            let JSONResponse = JSON.parse(body);

            if (JSONResponse.success) {
                this.setState({
                    busy: false,
                    mxid_registered: JSONResponse.mxid,
                    requestPIN: true
                })
            }
            else {
                this.setState({
                    busy: false,
                    requestPIN: false,
                    errorText: JSONResponse.error
                })
            }
        });
    }

    doLogin( pinCode ) {
        var self = this;

        this.setState({
            busy: false,
            errorText: ""
        });

        let legacyParams;
        const loginParams = {
            password: pinCode,
            identifier: {
                type: 'm.id.user',
                user: this.state.mxid_registered
            }
        }

        legacyParams = {
            user: this.state.mxid_registered
        };

        Object.assign(loginParams, legacyParams);
        this.state.matrixClient.login("m.login.password", loginParams).then(function(data) {
            Promise.resolve({
                homeserverUrl: self.state.matrixClient.getHomeserverUrl(),
                identityServerUrl: self.state.matrixClient.getIdentityServerUrl(),
                userId: data.user_id,
                deviceId: data.device_id,
                accessToken: data.access_token
            });

            let credentials = {
                userId: data.user_id,
                deviceId: data.device_id,
                accessToken: data.access_token,
                guest: false,
                homeserverUrl: self.state.matrixClient.getHomeserverUrl(),
                identityServerUrl: self.state.matrixClient.getIdentityServerUrl()
            }

            console.log("credentials")
            console.log(credentials)

            self.props.onLoggedIn(credentials);
        }, function( err) {
            self.setState({
                busy: false,
                errorText: err
            })
        });

    }

    requestVerificationPIN(phonePrefix, phoneNumber) {
        this.setState({
            requestPIN: false,
            busy: true,
            errorText: ""
        });

        // Validate the phone number entered
        if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
            this.setState({
                busy: false,
                errorText: _t('The phone number entered looks invalid')
            });

            return;
        }

        request({
            method: 'POST',
            url: SdkConfig.get().auth_pin_url,
            form: {
                phoneNumber: phonePrefix + "" + phoneNumber
            }
        }, (err, response, body) => {
            if ( err ) throw new Error(err);

            let JSONResponse = JSON.parse(body);

            this.setState({
                busy: false
            });

            if ( JSONResponse.success ) {
                this.setState({
                    requestPIN: true
                })
            }
            else {
                this.setState({
                    requestPIN: false,
                    errorText: JSONResponse.error
                })
            }
        });
    }

    _renderRegisterComponent() {        
        if (PHASES_ENABLED && this.state.phase !== PHASE_REGISTRATION) {
            return null;
        }

        const Spinner = sdk.getComponent('elements.Spinner');
        const RegistrationForm = sdk.getComponent('auth.RegistrationForm');

        if (!this.state.matrixClient && !this.state.busy) {
            return null;
        } 
        else if (this.state.busy || !this.state.flows) {
            return <div className="mx_AuthBody_spinner">
                <Spinner />
            </div>;
        } 
        else if (this.state.flows.length) {
            console.debug("RegistrationForm");
            let onEditServerDetailsClick = null;
            
            // If custom URLs are allowed and we haven't selected the Free server type, wire
            // up the server details edit link.
            if (
                PHASES_ENABLED &&
                !SdkConfig.get()['disable_custom_urls'] &&
                this.state.serverType !== ServerType.FREE
            ) {
                onEditServerDetailsClick = this.onEditServerDetailsClick;
            }
            
            return <RegistrationForm                
                defaultEmail={this.state.formVals.email}
                defaultPhoneCountry={this.state.formVals.phoneCountry}
                defaultPhoneNumber={this.state.formVals.phoneNumber}
                defaultPassword={this.state.formVals.password}
                defaultFullname={this.state.formVals.fullName}
                onRegisterClick={this._onFormSubmit}
                onEditServerDetailsClick={onEditServerDetailsClick}
                flows={this.state.flows}
                serverConfig={this.props.serverConfig}
                canSubmit={!this.state.serverErrorIsFatal}
                serverRequiresIdServer={this.state.serverRequiresIdServer}
                requestPIN={this.state.requestPIN}
                requestVerificationPIN={this.requestVerificationPIN}
                doLogin={this.doLogin}
            />;
        }
    }

    render() {
        const AuthHeader = sdk.getComponent('auth.AuthHeader');
        const AuthBody = sdk.getComponent("auth.AuthBody");
        const AuthPage = sdk.getComponent('auth.AuthPage');
        // const AccessibleButton = sdk.getComponent('elements.AccessibleButton');

        let errorText;
        const err = this.state.errorText;
        if (err) {
            errorText = <div className="mx_Login_error">{ err }</div>;
        }

        let serverDeadSection;
        if (!this.state.serverIsAlive) {
            const classes = classNames({
                "mx_Login_error": true,
                "mx_Login_serverError": true,
                "mx_Login_serverErrorNonFatal": !this.state.serverErrorIsFatal,
            });

            serverDeadSection = (
                <div className={classes}>
                    {this.state.serverDeadError}
                </div>
            );
        }

        let signIn;
        if ( !this.state.busy ) {
            signIn = <a className="mx_AuthBody_changeFlow" onClick={ this.onLoginClick } href="#">
                { _t('Sign in instead') }
            </a>;
        }

        let body = <div>
                <h2>{ _t('Create your account') }</h2>
                { errorText }
                { serverDeadSection }
                { this._renderRegisterComponent() }
                { signIn }
            </div>;

        return (
            <AuthPage>
                <AuthHeader />
                <AuthBody>
                    { body }
                </AuthBody>
            </AuthPage>
        );
    }
}