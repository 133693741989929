const PHONE_NUMBER_REGEXP = /^[0-9 -.]+$/;

export function looksValid(phoneNumber) {
    return PHONE_NUMBER_REGEXP.test(phoneNumber);
}

export const COUNTRIES = [
    {
        "iso2": "MX",
        "name": "Mexico",
        "prefix": "52",
    },
    {
        "iso2": "US",
        "name": "United States",
        "prefix": "1",
    },    
    {
        "iso2": "AR",
        "name": "Argentina",
        "prefix": "54",
    },    
    {
        "iso2": "CL",
        "name": "Chile",
        "prefix": "56",
    },
];